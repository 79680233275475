import { useMutation } from "@apollo/client";
import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";

import { Checkbox, makeStyles, TableCell, TableRow } from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import { Brands_brands_edges_node_advertiser } from "../../generated/Brands";

import { DeactivateBrand } from "../../generated/DeactivateBrand";
import { ReactivateBrand } from "../../generated/ReactivateBrand";
import DeactivateBrandMutation from "../../mutations/DeactivateBrandMutation";
import ReactivateBrandMutation from "../../mutations/ReactivateBrandMutation";

import ConfirmationAlert from "../ConfirmationAlert";

interface IBrandListItemProps {
  active: boolean;
  advertiser: Brands_brands_edges_node_advertiser;
  id: number;
  logoIcon?: string | null;
  name: string;
}

const BrandListItem = ({
  active,
  advertiser,
  id,
  logoIcon,
  name
}: IBrandListItemProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { hasPermission } = useAuth0();
  const updateBrands = hasPermission("manage:brands");

  const [activeConfirmationOpen, setActiveConfirmationOpen] = useState(false);

  const [deactivateBrand, { loading: deactivateLoading }] = useMutation<
    DeactivateBrand
  >(DeactivateBrandMutation, { variables: { brandId: id } });

  const [reactivateBrand, { loading: reactivateLoading }] = useMutation<
    ReactivateBrand
  >(ReactivateBrandMutation, { variables: { brandId: id } });

  const onActiveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActiveConfirmationOpen(true);
  };

  const onConfirmationNegative = () => {
    setActiveConfirmationOpen(false);
  };

  const onConfirmationPositive = () => {
    setActiveConfirmationOpen(false);
    active ? deactivateBrand() : reactivateBrand();
  };

  const onBrandClick = (brandId: number) => () => {
    history.push(`/brands/${brandId}/details`);
  };

  const activeConfirmationContent = active
    ? "Deactivating a brand will deactivate all offers and creatives, preventing click tracking."
    : "Reactivating a brand will allow new offers to be created.";
  const activeConfirmationPositiveAction = active ? "Deactivate" : "Reactivate";
  const activeConfirmationTitle = active
    ? `Deactivate Brand: ${name}`
    : `Reactivate Brand: ${name}`;

  return (
    <>
      <TableRow
        className={classes.tableRow}
        hover
        key={id}
        onClick={onBrandClick(id)}
      >
        <TableCell className={classes.cellId}>{id}</TableCell>
        <TableCell className={classes.cellLogo}>
          {logoIcon && <img alt="" height={60} src={logoIcon} />}
        </TableCell>
        <TableCell>{name}</TableCell>
        {updateBrands && <TableCell>{advertiser.name}</TableCell>}
        <TableCell>
          <Checkbox
            checked={active}
            disabled={!updateBrands || deactivateLoading || reactivateLoading}
            onClick={onActiveClick}
          />
        </TableCell>
      </TableRow>

      {updateBrands && (
        <ConfirmationAlert
          content={activeConfirmationContent}
          onNegative={onConfirmationNegative}
          onPositive={onConfirmationPositive}
          open={activeConfirmationOpen}
          positiveAction={activeConfirmationPositiveAction}
          title={activeConfirmationTitle}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  cellId: { textAlign: "right" },
  cellLogo: { textAlign: "center" },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  tableRow: { cursor: "pointer" }
}));

export default BrandListItem;
