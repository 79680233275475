import { gql } from "@apollo/client";

const AccountsQuery = gql`
  query Accounts(
    $accountPoolId: ID!
    $after: ID
    $before: ID
    $limit: Int! = 50
    $userId: ID
  ) {
    accounts(
      accountPoolId: $accountPoolId
      after: $after
      before: $before
      limit: $limit
      userId: $userId
    ) {
      edges {
        cursor
        node {
          id
          user {
            id
            name
          }
          username
          variables {
            name
            value
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default AccountsQuery;
