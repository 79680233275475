import { useMutation } from "@apollo/client";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Switch,
  Tooltip,
  Typography
} from "@material-ui/core";
import { format } from "date-fns";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import ConfirmationAlert from "../ConfirmationAlert";
import InputMultiBrandSearch from "../InputMultiBrandSearch";

import UpdateMonthlyPaymentSettingsMutation from "../../mutations/UpdateMonthlyPaymentSettings";
import MonthlyPaymentSettingsQuery from "../../queries/MonthlyPaymentSettingsQuery";

import {
  monthlyPaymentSummaries_monthlyPaymentSettings_error,
  monthlyPaymentSummaries_monthlyPaymentSettings_monthlyPaymentSetting
} from "../../generated/monthlyPaymentSummaries";

interface IMonthlyPaymentSettingsDialogProps {
  date: string;
  onClose: () => void;
  open: boolean;
  settings: monthlyPaymentSummaries_monthlyPaymentSettings_monthlyPaymentSetting;
}

const MonthlyPaymentSettingsForm = ({
  date,
  onClose,
  open,
  settings
}: IMonthlyPaymentSettingsDialogProps) => {
  const prefilledRingfenceOnCommission = settings.ringfenceCommissionType;
  const prefilledBrandBundle = settings.brandBundle;
  const classes = useStyles();
  const [monthState, setMonthState] = useState<string>(date);
  const [ringfenceOnCommission, setRingfenceOnCommission] = useState<boolean>(
    prefilledRingfenceOnCommission
  );
  const [bundledBrands, setBundledBrands] = useState<number[]>(
    prefilledBrandBundle
  );

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [
    settingsError,
    setSettingsError
  ] = useState<monthlyPaymentSummaries_monthlyPaymentSettings_error | null>(
    null
  );

  useEffect(() => {
    setMonthState(date);
    setRingfenceOnCommission(prefilledRingfenceOnCommission);
    setBundledBrands(prefilledBrandBundle);
  }, [date, prefilledRingfenceOnCommission, prefilledBrandBundle]);

  const [updateSettings] = useMutation(UpdateMonthlyPaymentSettingsMutation, {
    onCompleted: result => {
      if (result.updateMonthlyPaymentSettings.error) {
        setSettingsError(result.updateMonthlyPaymentSettings.error);
      }
    },
    variables: {
      brandBundle: bundledBrands,
      month: monthState,
      ringfenceCommissionType: ringfenceOnCommission
    }
  });

  const handleRingfenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setRingfenceOnCommission(event.target.checked);
  };

  const handleBrandBundleChange = (brands: number[]) => {
    setDirty(true);
    setBundledBrands(brands);
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    onClose();
    setRingfenceOnCommission(settings.ringfenceCommissionType);
    setBundledBrands(settings.brandBundle);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    updateSettings({
      refetchQueries: [
        {
          query: MonthlyPaymentSettingsQuery,
          variables: { month: monthState }
        }
      ]
    }).then(() => onCloseReset());
  };

  const splitDate = monthState.split("-");
  const month = format(
    new Date(
      parseInt(splitDate[0], 10),
      parseInt(splitDate[1], 10) - 1,
      parseInt(splitDate[2], 10)
    ),
    "LLLL"
  );
  const year = splitDate[0];

  const ringfenceCommissionTitle =
    "If checked, a partners negative monthly RS earnings for subtotals of Hybrid and Straight Revshare deals are treated independantly and zeroed out for the Monthly Payments module.";
  const brandBundleTitle =
    "If left unchecked, a given partners revenue share will be ringfenced (calculated independently) for each brand, so that one brands negative revenue share would not affect earnings on other brands. If brands are chosen to be “bundled” (checked), a partners earnings among bundled brands is totaled, and ringfenced independently of unchecked brands.";
  return (
    <>
      <Dialog
        fullWidth
        onClose={handleClose}
        open={open}
        onExited={() => {
          onCloseReset();
        }}
      >
        <form onSubmit={onSubmit}>
          <Container className={classes.padTop}>
            <Typography variant="h6">
              Monthly Settings - {`${month} ${year}`}
            </Typography>
            <Typography variant="body1">
              Update parameters which affect the monthly payment calculations.
            </Typography>
          </Container>
          <DialogContent className={classes.padTop}>
            <Typography variant="h6">
              Revenue Share Ringfencing Options
            </Typography>
            <div className={classes.overrideContainer}>
              <Tooltip arrow placement="right" title={ringfenceCommissionTitle}>
                <Typography variant="body1">
                  Ringfence agreement commission type
                </Typography>
              </Tooltip>
              <Switch
                checked={ringfenceOnCommission}
                onChange={handleRingfenceChange}
                name="ringfenceOnCommission"
                color="secondary"
              />
            </div>
            <div className={classes.padContainer}>
              <Tooltip arrow placement="right" title={brandBundleTitle}>
                <Typography variant="h6">Brand Bundling</Typography>
              </Tooltip>
              <InputMultiBrandSearch
                bundledBrands={bundledBrands}
                onSelect={handleBrandBundleChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onClose();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />

      {settingsError && (
        <ConfirmationAlert
          content={""}
          onPositive={async () => {
            setSettingsError(null);
          }}
          open={true}
          positiveAction={"Ok"}
          title={"Action Error"}
        >
          <DialogContentText
            className={classes.whiteSpace}
          >{`${settingsError.message}`}</DialogContentText>
        </ConfirmationAlert>
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  overrideContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: spacing(1)
  },
  padContainer: {
    paddingBottom: spacing(2),
    paddingTop: spacing(2)
  },
  padTop: {
    paddingTop: spacing(2)
  },
  whiteSpace: {
    whiteSpace: "pre-wrap"
  }
}));

export default MonthlyPaymentSettingsForm;
