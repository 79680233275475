import { useMutation, useQuery } from "@apollo/client";
import React, { KeyboardEvent, useState } from "react";

import {
  AppBar,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import {
  ApproveUsers,
  ApproveUsersVariables
} from "../../generated/ApproveUsers";
import { SortOrder, UserSortFields } from "../../generated/globalTypes";
import { Roles as IRolesQuery } from "../../generated/Roles";
import { Users_users_users } from "../../generated/Users";
import ApproveUsersMutation from "../../mutations/ApproveUsersMutation";
import RolesQuery from "../../queries/RolesQuery";
import UsersQuery from "../../queries/UsersQuery";

import BulkUserExportModal from "../BulkUserExportModal";
import ConfirmationAlert from "../ConfirmationAlert";
import SortableTableHeader from "../SortableTableHeader";
import UserConfirmation from "../UserConfirmation";
import UserForm from "../UserForm";
import UserListItem from "../UserListItem";

interface IUserListProps {
  currentPage: number;
  onChangePage: (page: number) => void;
  onChangeSearch?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChangeSort: (attribute: UserSortFields, order: SortOrder) => void;
  onChangeTab: (tab: number) => void;
  onSetSortOrder: (order: SortOrder) => void;
  sortAttribute: UserSortFields;
  sortOrder: SortOrder;
  tabIndex: number;
  total: number;
  users: Users_users_users[];
}

const UserList = ({
  currentPage,
  onChangePage,
  onChangeSearch,
  onChangeSort,
  onChangeTab,
  onSetSortOrder,
  sortAttribute,
  sortOrder,
  tabIndex,
  total,
  users
}: IUserListProps) => {
  const classes = useStyles();

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [bulkUserExport, setBulkUserExport] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [approveSelected, setApproveSelected] = useState(false);
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [approveUserIds, setApproveUserIds] = useState<string[]>([]);

  const [approveUsers, { loading }] = useMutation<
    ApproveUsers,
    ApproveUsersVariables
  >(ApproveUsersMutation);

  const { data: rolesData } = useQuery<IRolesQuery>(RolesQuery);

  const handleSetApproveUsers = (userId: string, approve: boolean) => {
    if (approve) {
      setApproveUserIds(approveUserIds.concat([userId]));
    } else {
      setApproveUserIds(
        approveUserIds.filter(approveUserId => approveUserId !== userId)
      );
    }
  };

  const handleResetApproveUsers = () => {
    setApproveSelected(false);
    setSelectMultiple(false);
    setApproveUserIds([]);
  };

  const handleApproveUsers = () => {
    approveUsers({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: UsersQuery,
          variables: { approved: false, page: currentPage }
        },
        { query: UsersQuery, variables: { approved: true, page: currentPage } }
      ],
      variables: { userIds: approveUserIds }
    }).then(() => {
      setApproveUserIds([]);
      setSelectMultiple(false);
    });
    setApproveSelected(false);
  };

  return (
    <>
      <Paper>
        <div className={classes.header}>
          <Typography variant="h5">Users</Typography>

          {onChangeSearch && (
            <TextField
              className={classes.searchInput}
              id="users-search"
              onKeyDown={onChangeSearch}
              InputProps={{
                inputProps: { tabIndex: 1 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
          <div>
            <Button
              color="primary"
              onClick={() => setAddUserOpen(true)}
              variant="contained"
              className={classes.buttonIcon}
            >
              <AddIcon className={classes.buttonIcon} />
              User
            </Button>
            <Tooltip title="Export User List and Details" arrow>
              <Button
                color="default"
                onClick={() => setBulkUserExport(true)}
                variant="contained"
              >
                Export
              </Button>
            </Tooltip>
          </div>
        </div>

        <AppBar
          className={classes.appBar}
          color="default"
          elevation={0}
          position="static"
        >
          <Tabs
            onChange={(_, value: number) => {
              const order =
                value === 1 ? SortOrder.DESCENDING : SortOrder.ASCENDING;
              onChangeTab(value);
              onSetSortOrder(order);
            }}
            value={tabIndex}
          >
            <Tab label="Approved" />
            <Tab label="Unapproved" />
          </Tabs>

          {!!tabIndex &&
            (selectMultiple ? (
              <>
                <Button
                  className={classes.approveButton}
                  color="secondary"
                  disabled={loading}
                  onClick={handleResetApproveUsers}
                  variant="contained"
                >
                  Cancel
                </Button>

                <Button
                  className={classes.approveSelectedButton}
                  color="primary"
                  disabled={approveUserIds.length === 0 || loading}
                  onClick={() => setApproveSelected(true)}
                  variant="contained"
                >
                  Approve Selected
                </Button>
              </>
            ) : (
              <Button
                className={classes.approveButton}
                color="primary"
                onClick={() => setSelectMultiple(true)}
                variant="contained"
              >
                Select Multiple
              </Button>
            ))}
        </AppBar>

        <div className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {!!tabIndex && <TableCell></TableCell>}
                {!tabIndex && (
                  <TableCell className={classes.partnerId}>
                    Partner ID
                  </TableCell>
                )}
                <TableCell className={classes.nameCell}>
                  <SortableTableHeader
                    label="Name"
                    onChangeSort={onChangeSort}
                    self={UserSortFields.NAME}
                    sortAttribute={sortAttribute}
                    sortOrder={sortOrder}
                  />
                </TableCell>
                <TableCell className={classes.emailCell}>
                  <SortableTableHeader
                    label="Email"
                    onChangeSort={onChangeSort}
                    self={UserSortFields.EMAIL}
                    sortAttribute={sortAttribute}
                    sortOrder={sortOrder}
                  />
                </TableCell>
                <TableCell className={classes.companyCell}>Company</TableCell>
                <TableCell className={classes.phoneNumberCell}>
                  Phone Number
                </TableCell>
                <TableCell className={classes.createdAtCell}>
                  <SortableTableHeader
                    label="Registered At"
                    onChangeSort={onChangeSort}
                    self={UserSortFields.CREATED_AT}
                    sortAttribute={sortAttribute}
                    sortOrder={sortOrder}
                  />
                </TableCell>
                <TableCell className={classes.referredByCell}>
                  Referred By
                </TableCell>
                <TableCell className={classes.activeCell}>Role</TableCell>
                <TableCell className={classes.activeCell}>Active</TableCell>
                <TableCell className={classes.actionCell} />
              </TableRow>
            </TableHead>

            <TableBody>
              {users.map(user => (
                <UserListItem
                  disableButtons={loading}
                  fadeOut={loading && approveUserIds.includes(user.id)}
                  handleSetApproveUsers={handleSetApproveUsers}
                  key={user.id}
                  page={currentPage}
                  roleOptions={rolesData?.roles?.roles || []}
                  selectMultiple={selectMultiple}
                  {...user}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  onPageChange={(_, page) => onChangePage(page)}
                  page={currentPage}
                  rowsPerPage={parseInt(
                    process.env.REACT_APP_AUTH0_PER_PAGE || "30",
                    10
                  )}
                  rowsPerPageOptions={[]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>

      <UserForm
        onClose={() => setAddUserOpen(false)}
        onSetNewUser={setNewUser}
        open={addUserOpen}
        roleOptions={rolesData?.roles?.roles || []}
      />

      <BulkUserExportModal
        onClose={() => setBulkUserExport(false)}
        open={bulkUserExport}
      />

      <UserConfirmation onClose={() => setNewUser(null)} user={newUser} />

      <ConfirmationAlert
        content={`Are you sure you want to approve selected users?`}
        onNegative={handleResetApproveUsers}
        onPositive={handleApproveUsers}
        open={approveSelected}
        positiveAction="Approve"
        title={"Approve Confirm"}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  actionCell: {
    minWidth: 128,
    width: "15%"
  },
  activeCell: { width: "5%" },
  appBar: {
    flexDirection: "row"
  },
  approveButton: {
    margin: `auto ${spacing(3)}px auto auto`
  },
  approveSelectedButton: {
    margin: `auto ${spacing(3)}px auto 0px`
  },
  buttonIcon: {
    marginRight: spacing()
  },
  companyCell: { width: "10%" },
  createdAtCell: {
    minWidth: 138,
    width: "11%"
  },
  emailCell: { width: "15%" },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(2, 3)
  },
  nameCell: {
    minWidth: 150,
    width: "15%"
  },
  partnerId: {
    whiteSpace: "nowrap"
  },
  phoneNumberCell: {
    minWidth: 175,
    width: "15%"
  },
  referredByCell: { minWidth: 150, width: "15%" },
  searchInput: { flex: 0.25 },
  tableContainer: {
    padding: spacing(0, 3)
  }
}));

export default UserList;
