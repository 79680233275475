import { gql } from "@apollo/client";

const OfferQuery = gql`
  query Offer($offerId: Int!) {
    offer(offerId: $offerId) {
      offer {
        active
        trackingLink
        trackingLinkAppend
        accountPool {
          id
        }
        advertiserAccountRequired
        brand {
          active
          id
          name
          trackingLink
          trackingLinkAppend
          advertiser {
            id
            name
          }
        }
        currentAgreement {
          id
          affiliateDetails {
            commissionType
            currency
            tierType
            ... on AgreementClawback {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementCpa {
              actionType
              cpaAmount
            }
            ... on AgreementHybrid {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementRevshare {
              revsharePercentages
            }
            ... on AgreementSpend {
              spendAccessToken
              spendPercentage
            }
          }
          networkDetails {
            commissionType
            currency
            tierType
            ... on AgreementClawback {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementCpa {
              actionType
              cpaAmount
            }
            ... on AgreementHybrid {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementRevshare {
              revsharePercentages
            }
          }
          qualifications {
            amount
            id
            qualificationType
          }
          networkTiers {
            cpa
            payType
            revsharePercentages
            threshold
            thresholdType
          }
          partnerTiers {
            cpa
            payType
            revsharePercentages
            threshold
            thresholdType
          }
          ringfenceRevshare
          updatedAt
        }
        defaultAgreement {
          id
          affiliateDetails {
            commissionType
            currency
            tierType
            ... on AgreementClawback {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementCpa {
              actionType
              cpaAmount
            }
            ... on AgreementHybrid {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementRevshare {
              revsharePercentages
            }
            ... on AgreementSpend {
              spendAccessToken
              spendPercentage
            }
          }
          networkDetails {
            commissionType
            currency
            tierType
            ... on AgreementClawback {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementCpa {
              actionType
              cpaAmount
            }
            ... on AgreementHybrid {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementRevshare {
              revsharePercentages
            }
          }
          qualifications {
            amount
            qualificationType
            windowType
          }
          networkTiers {
            cpa
            revsharePercentages
            threshold
            thresholdType
            windowType
          }
          partnerTiers {
            cpa
            revsharePercentages
            threshold
            thresholdType
            windowType
          }
          ringfenceRevshare
          startAt
          endAt
          updatedAt
        }
        id
        mobileOptimized
        name
        overview
        playerBonuses
        terms
        trackingLink
        trackingLinkAppend
        verticals
        visible
      }
      error {
        errorType
      }
    }
  }
`;

export default OfferQuery;
