import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import { CreateUser_createUser_user } from "../../generated/CreateUser";

interface IUserConfirmation {
  onClose: () => void;
  user: CreateUser_createUser_user | null;
}
const UserConfirmation = ({ onClose, user }: IUserConfirmation) => {
  return (
    user && (
      <Dialog onClose={onClose} open={!!user}>
        <DialogTitle>New User Added</DialogTitle>

        <DialogContent>
          <DialogContentText>{`Name: ${user.name}`}</DialogContentText>

          <DialogContentText>{`Email: ${user.email}`}</DialogContentText>

          <DialogContentText>
            {user.company && `Company: ${user.company}`}
          </DialogContentText>

          <DialogContentText>
            {user.phoneNumber && `Phone Number: ${user.phoneNumber}`}
          </DialogContentText>

          <DialogContentText>
            {user.currency && `Currency: ${user.currency}`}
          </DialogContentText>

          <DialogContentText>
            {user.roles[0].name && `Role: ${user.roles[0].name}`}
          </DialogContentText>

          <DialogContentText>{`Invite Url: ${user.inviteUrl}`}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={onClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default UserConfirmation;
