import { gql } from "@apollo/client";

const ImportSubagreementConversions = gql`
  mutation ImportSubagreementConversions(
    $csvFile: Upload!
    $date: DateTime!
    $override: Boolean
  ) {
    importSubagreementConversions(
      csvFile: $csvFile
      date: $date
      override: $override
    ) {
      clicksUpdated {
        id
      }
      error {
        errorType
      }
      failedRows
      filename
    }
  }
`;

export default ImportSubagreementConversions;
