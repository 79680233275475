import { gql } from "@apollo/client";

const CreateDefaultAgreementMutation = gql`
  mutation CreateDefaultAgreement(
    $affiliateDetails: AgreementDetailsInput!
    $networkDetails: AgreementDetailsInput
    $networkTiers: [CreateTierInput!]
    $offerId: Int!
    $partnerTiers: [CreateTierInput!]
    $qualifications: [CreateQualificationInput!]
    $ringfenceRevshare: Boolean
    $startAt: DateTime
    $endAt: DateTime
  ) {
    createDefaultAgreement(
      input: {
        affiliateDetails: $affiliateDetails
        networkDetails: $networkDetails
        networkTiers: $networkTiers
        offerId: $offerId
        partnerTiers: $partnerTiers
        qualifications: $qualifications
        ringfenceRevshare: $ringfenceRevshare
        startAt: $startAt
        endAt: $endAt
      }
    ) {
      agreement {
        affiliateDetails {
          commissionType
          currency
          ... on AgreementClawback {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementCpa {
            actionType
            cpaAmount
          }
          ... on AgreementHybrid {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementRevshare {
            revsharePercentages
          }
          tierType
        }
        networkDetails {
          commissionType
          currency
          ... on AgreementClawback {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementCpa {
            actionType
            cpaAmount
          }
          ... on AgreementHybrid {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementRevshare {
            revsharePercentages
          }
          tierType
        }
        qualifications {
          amount
          qualificationType
          windowType
        }
        networkTiers {
          cpa
          revsharePercentages
          threshold
          thresholdType
          windowType
        }
        partnerTiers {
          cpa
          revsharePercentages
          threshold
          thresholdType
          windowType
        }
        startAt
        endAt
      }
    }
  }
`;

export default CreateDefaultAgreementMutation;
