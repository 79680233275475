import { gql } from "@apollo/client";

const SubagreementsQuery = gql`
  query Subagreements(
    $after: ID
    $before: ID
    $limit: Int! = 50
    $offerId: Int
    $search: String
    $userId: ID
  ) {
    subagreements(
      after: $after
      before: $before
      limit: $limit
      offerId: $offerId
      userId: $userId
      search: $search
    ) {
      edges {
        cursor
        node {
          id
          account {
            id
            username
          }
          affiliateDetails {
            commissionType
            currency
            tierType
            ... on AgreementClawback {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementCpa {
              actionType
              cpaAmount
            }
            ... on AgreementHybrid {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementRevshare {
              revsharePercentages
            }
            ... on AgreementSpend {
              spendAccessToken
              spendPercentage
            }
          }
          networkDetails {
            commissionType
            currency
            tierType
            ... on AgreementClawback {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementCpa {
              actionType
              cpaAmount
            }
            ... on AgreementHybrid {
              actionType
              cpaAmount
              revsharePercentages
            }
            ... on AgreementRevshare {
              revsharePercentages
            }
          }
          qualifications {
            amount
            id
            qualificationType
            windowType
          }
          networkTiers {
            cpa
            id
            payType
            revsharePercentages
            threshold
            thresholdType
            windowType
          }
          partnerTiers {
            cpa
            id
            payType
            revsharePercentages
            threshold
            thresholdType
            windowType
          }
          ringfenceRevshare
          startAt
          endAt
          updatedAt
          status
          user {
            displayId
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default SubagreementsQuery;
