import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField
} from "@material-ui/core";

import { UpdateReferrerVariables } from "../../generated/UpdateReferrer";
import UpdateReferrerMutation from "../../mutations/UpdateReferrerMutation";

import ConfirmationAlert from "../ConfirmationAlert";
import InputUserSearch from "../InputUserSearch";

interface IUserReferrerFormProps {
  onClose: () => void;
  open: boolean;
  referrer?: {
    id: string;
    name: string;
  };
  referrerEarningsPercentage: number;
  referrerRevsharePercentage: number;
  userId: string;
}

const UserReferrerForm = ({
  onClose,
  open,
  referrer,
  referrerEarningsPercentage,
  referrerRevsharePercentage,
  userId
}: IUserReferrerFormProps) => {
  const classes = useStyles();

  const [dirty, setDirty] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [referrerIdState, setReferrerIdState] = useState(referrer?.id);
  const [
    referrerEarningsPercentageState,
    setReferrerEarningsPercentage
  ] = useState(referrerEarningsPercentage);
  const [
    referrerRevsharePercentageState,
    setReferrerRevsharePercentage
  ] = useState(referrerRevsharePercentage);

  const [updateReferrer, { loading }] = useMutation(UpdateReferrerMutation);

  const setInputState = (setFunction: (value: number) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    setReferrerEarningsPercentage(referrerEarningsPercentageState);
    setReferrerRevsharePercentage(referrerRevsharePercentageState);
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const variables = {
      referrerEarningsPercentage: referrerEarningsPercentageState,
      referrerId: referrerIdState,
      referrerRevsharePercentage: referrerRevsharePercentageState,
      userId
    } as UpdateReferrerVariables;

    updateReferrer({
      awaitRefetchQueries: true,
      refetchQueries: ["Users"],
      variables
    }).then(() => onCloseReset());
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>Update Referrer</DialogTitle>

          <DialogContent>
            <InputUserSearch
              className={classes.formInput}
              onSelect={setReferrerIdState}
              staticUser={referrer}
            />

            <TextField
              className={classes.formInput}
              id="referrer-earnings-percentage"
              inputProps={{ min: 0 }}
              fullWidth
              label="Referrer Earnings Percentage"
              onChange={setInputState(setReferrerEarningsPercentage)}
              required
              type="number"
              value={
                referrerEarningsPercentageState === 0 ||
                referrerEarningsPercentageState
                  ? referrerEarningsPercentageState
                  : ""
              }
            />

            <TextField
              className={classes.formInput}
              id="referrer-revshare-percentage"
              inputProps={{ min: 0 }}
              fullWidth
              label="Referrer Revshare Percentage"
              onChange={setInputState(setReferrerRevsharePercentage)}
              required
              type="number"
              value={
                referrerRevsharePercentageState === 0 ||
                referrerRevsharePercentageState
                  ? referrerRevsharePercentageState
                  : ""
              }
            />
          </DialogContent>

          <DialogActions>
            <Button disabled={loading || !open} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading || !open}
              type="submit"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formInput: { marginBottom: spacing(2) }
}));

export default UserReferrerForm;
