import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import { useAuth0 } from "../../utils/auth0Provider";

import {
  Offers_offers_edges_node,
  Offers_offers_edges_node_brand
} from "../../generated/Offers";

import OfferForm from "../OfferForm";
import OfferListItem from "../OfferListItem";

interface IOfferListProps {
  brand?: Offers_offers_edges_node_brand;
  handleSearch: (value?: string) => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  offers: Offers_offers_edges_node[];
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const OfferList = ({
  brand,
  handleSearch,
  hasNextPage,
  hasPreviousPage,
  offers,
  onNextPage,
  onPreviousPage
}: IOfferListProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const createOffers = hasPermission("manage:offers");
  const updateOffers = hasPermission("manage:offers");

  const [offerSelected, setOfferSelected] = useState<
    Offers_offers_edges_node
  >();
  const [offerFormOpen, setOfferFormOpen] = useState(false);
  const [offerSearch, setOfferSearch] = useState("");

  const handleOfferSearch = (value: string) => {
    setOfferSearch(value);
    handleSearch(value || undefined);
  };

  const onOfferUpdate = (offer: Offers_offers_edges_node) => {
    setOfferSelected(offer);
    setOfferFormOpen(true);
  };

  const resetOfferSelected = () => {
    if (offerSelected) {
      setOfferSelected(undefined);
    }
  };

  const tableView = (
    <>
      <div className={classes.header}>
        <Typography variant="h5">Offers</Typography>

        <TextField
          id="offers-search"
          onChange={event => handleOfferSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          value={offerSearch}
        />

        {createOffers && (
          <Button
            color="primary"
            onClick={() => setOfferFormOpen(true)}
            variant="contained"
          >
            <AddIcon className={classes.buttonIcon} />
            Offer
          </Button>
        )}
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellId}>ID</TableCell>
            <TableCell className={classes.cellName}>Name</TableCell>
            <TableCell>Vertical</TableCell>
            <TableCell>Brand</TableCell>
            {updateOffers && <TableCell>Advertiser</TableCell>}
            <TableCell className={classes.cellAction}>Active</TableCell>
            {updateOffers && <TableCell className={classes.cellAction} />}
          </TableRow>
        </TableHead>

        <TableBody>
          {offers.map(offer => (
            <OfferListItem
              active={offer.active}
              advertiser={offer.brand.advertiser}
              brand={offer.brand}
              id={offer.id}
              name={offer.name}
              key={offer.id}
              onOfferUpdate={() => onOfferUpdate(offer)}
              verticals={offer.verticals}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell} colSpan={1000}>
              <Toolbar className={classes.toolbar}>
                <ButtonGroup>
                  <Button disabled={!hasPreviousPage} onClick={onPreviousPage}>
                    {"<"}
                  </Button>
                  <Button disabled={!hasNextPage} onClick={onNextPage}>
                    {">"}
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );

  return (
    <>
      {brand ? tableView : <Paper className={classes.paper}>{tableView}</Paper>}
      <OfferForm
        brand={offerSelected?.brand || brand}
        offer={offerSelected}
        onClose={() => setOfferFormOpen(false)}
        onExited={resetOfferSelected}
        open={offerFormOpen}
        type={offerSelected ? "update" : "create"}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: { marginRight: spacing() },
  cellAction: { width: "5%" },
  cellId: {
    textAlign: "right",
    width: "5%"
  },
  cellName: { width: "30%" },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: { padding: 0 },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default OfferList;
