import { useQuery } from "@apollo/client";
import React from "react";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs
} from "@material-ui/core";

import MonthlyPaymentsQuery from "../../queries/MonthlyPaymentsQuery";

import {
  monthlyPayments as IMonthlyPayments,
  monthlyPayments_monthlyPayments_edges,
  monthlyPaymentsVariables
} from "../../generated/monthlyPayments";

interface IMonthlyPaymentDialogProps {
  onClose: () => void;
  open: boolean;
  month: any;
  partnerId: string;
  partnerName: string;
  partnerDisplayId: number;
  paymentType: number;
  setPaymentType: (value: number) => void;
}

const MonthlyPaymentDialog = ({
  onClose,
  open,
  month,
  partnerId,
  partnerName,
  partnerDisplayId,
  paymentType,
  setPaymentType
}: IMonthlyPaymentDialogProps) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPaymentType(newValue);
  };

  const handleClose = () => {
    onClose();
  };

  const paymentVariables: monthlyPaymentsVariables = {
    limit: 20,
    month,
    userId: partnerId
  };

  const { loading, data } = useQuery<IMonthlyPayments>(MonthlyPaymentsQuery, {
    skip: !open,
    variables: paymentVariables
  });

  const entries = data?.monthlyPayments?.edges || [];

  const payableActions = (subtotal: number, rate: number) => {
    if (rate === 0) {
      return "0";
    }
    const result = subtotal / rate || 0;
    return result.toString();
  };

  const paymentTotal = (arr: monthlyPayments_monthlyPayments_edges[]) => {
    const cpaTotals = arr.map(entry => entry.node.cpaTotal);

    const sum =
      cpaTotals.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      ) || 0;

    const totalsCurrency =
      arr.length > 0 ? arr[0].node.subagreement.affiliateDetails.currency : "";

    return `${sum} ${totalsCurrency}`;
  };

  const renderTable = () => {
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.bold}>Offer</TableCell>
                <TableCell className={classes.bold} align="center">
                  CPA Rate
                </TableCell>
                <TableCell className={classes.bold} align="center">
                  Payable Actions
                </TableCell>
                <TableCell className={classes.bold} align="center">
                  Subtotals
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map(entry => {
                const {
                  node: {
                    id,
                    cpaTotal,
                    subagreement: {
                      offer: { name: offerName }
                    }
                  }
                } = entry;

                const affiliateDetails =
                  entry.node.subagreement.affiliateDetails;

                const affiliateCurrency =
                  entry.node.subagreement.affiliateDetails.currency;

                const affiliateRate =
                  affiliateDetails &&
                  affiliateDetails.__typename === "AgreementCpa"
                    ? affiliateDetails.cpaAmount
                    : 0;

                return (
                  <TableRow key={id}>
                    <TableCell>{offerName}</TableCell>
                    <TableCell align="center">{`${affiliateRate} ${affiliateCurrency}`}</TableCell>
                    <TableCell align="center">
                      {payableActions(cpaTotal, affiliateRate)}
                    </TableCell>
                    <TableCell align="center">{`${cpaTotal} ${affiliateCurrency}`}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell className={classes.bold}>{"CPA TOTAL"}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell className={classes.bold} align="center">
                  {paymentTotal(entries)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="xl">
        <DialogTitle>{`${partnerName} (${partnerDisplayId.toString()}) Breakdown`}</DialogTitle>
        <DialogContent>
          <Paper>
            <Tabs
              value={paymentType}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab label="CPA" />
              <Tab label="Rev Share" />
              <Tab label="Adjustments" />
              <Tab label="Rollover" />
              <Tab label="Total" />
            </Tabs>
          </Paper>
          {renderTable()}
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles({
  bold: { fontWeight: "bold" },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "3rem 0 3rem 0"
  },
  tableContainer: {
    padding: "1rem 0 3rem 0"
  }
});

export default MonthlyPaymentDialog;
