import { useMutation } from "@apollo/client";
import React, { FormEvent, useState } from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Typography
} from "@material-ui/core";

import {
  ImportConversions,
  ImportConversionsVariables
} from "../../generated/ImportConversions";
import {
  ImportSubagreementConversions,
  ImportSubagreementConversionsVariables
} from "../../generated/ImportSubagreementConversions";
import ImportConversionsMutation from "../../mutations/ImportConversionsMutation";
import ImportSubagreementConversionsMutation from "../../mutations/ImportSubagreementConversionsMutation";
import CsvDropzone from "../CsvDropzone";

enum ConversionType {
  CLICK_ID = "CLICK_ID",
  TRACKING_ID = "TRACKING_ID"
}

const CsvForm = () => {
  const classes = useStyles();

  const [csvFile, setCsvFile] = useState<File | undefined>();
  const [conversionTypeState, setConversionType] = useState<ConversionType>();
  const [override, setOverride] = useState(false);
  const [date, setDate] = useState<string>();
  const [uploadComplete, setUploadComplete] = useState(false);

  const [
    importConversions,
    { loading: loadingImportConversions }
  ] = useMutation<ImportConversions, ImportConversionsVariables>(
    ImportConversionsMutation,
    { onCompleted: () => setUploadComplete(true) }
  );

  const [
    importSubagreementConversions,
    { loading: loadingImportSubagreementConversions }
  ] = useMutation<
    ImportSubagreementConversions,
    ImportSubagreementConversionsVariables
  >(ImportSubagreementConversionsMutation, {
    onCompleted: () => setUploadComplete(true)
  });

  const resetOverride = () => {
    setOverride(false);
    setDate(undefined);
    setUploadComplete(false);
  };

  const onConversionTypeSelect = (conversionType: ConversionType) => {
    if (uploadComplete) {
      setCsvFile(undefined);
    }
    resetOverride();
    setConversionType(conversionType);
  };

  const onDropCsvFile = (file: File | undefined) => {
    resetOverride();
    setCsvFile(file);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const variables = {
      csvFile,
      date: date || new Date().toISOString(),
      override
    };

    if (conversionTypeState === ConversionType.CLICK_ID) {
      importConversions({
        update: (cache, data) => {
          data.data?.importConversions?.clicksUpdated?.map(click =>
            cache.evict(`Click:${click.id}`)
          );
        },
        variables
      });
    } else {
      importSubagreementConversions({
        update: (cache, data) => {
          data.data?.importSubagreementConversions?.clicksUpdated?.map(click =>
            cache.evict(`Click:${click.id}`)
          );
        },
        variables
      });
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.header} variant="h5">
        Import CSV
      </Typography>

      <form className={classes.form} onSubmit={onSubmit}>
        <FormControl className={classes.input} required>
          <InputLabel htmlFor="conversion-type">Conversion Type</InputLabel>
          <Select
            inputProps={{ id: "conversion-type" }}
            native
            onChange={event =>
              onConversionTypeSelect(event.target.value as ConversionType)
            }
            required
            value={conversionTypeState}
          >
            <option value="" />
            {Object.values(ConversionType).map(conversionType => (
              <option key={conversionType} value={conversionType}>
                {conversionType === ConversionType.CLICK_ID
                  ? `${conversionType.replace("_", " ")} (Standard)`
                  : conversionType.replace("_", " ")}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <CsvDropzone
            complete={uploadComplete}
            disabled={
              loadingImportConversions || loadingImportSubagreementConversions
            }
            fileName={csvFile?.name}
            onDropCsvFile={onDropCsvFile}
          />
        </FormControl>

        <div>
          <FormControl>
            <InputLabel htmlFor="date" shrink={true}>
              Date
            </InputLabel>
            <Input
              id="date"
              onChange={event => setDate(event.target.value)}
              type="date"
              value={date || ""}
            />
          </FormControl>
        </div>

        <div className={classes.override}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={override}
                onChange={event => setOverride(event.target.checked)}
              />
            }
            label="Override"
          />
        </div>

        <Button
          className={classes.button}
          disabled={
            !csvFile ||
            loadingImportConversions ||
            loadingImportSubagreementConversions ||
            uploadComplete
          }
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: "fit-content"
  },
  checkbox: {
    marginRight: spacing(3)
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content"
  },
  header: {
    alignItems: "center",
    display: "flex",
    paddingBottom: spacing()
  },
  input: {
    paddingBottom: spacing(2)
  },
  override: {
    display: "flex",
    paddingBottom: spacing(3),
    paddingTop: spacing(2)
  },
  paper: {
    padding: spacing(3)
  }
}));

export default CsvForm;
