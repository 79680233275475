import React from "react";

import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";

import {
  ActionType,
  CommissionType,
  Currency,
  VerticalType
} from "../../generated/globalTypes";
import getCurrencySymbol from "../../utils/getCurrencySymbol";
import { IVerticalMap } from "../../utils/verticalsMap";

(String.prototype as any).capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

interface IAgreementDetailsCard {
  actionType?: ActionType;
  commissionType: CommissionType;
  cpaAmount?: number;
  currency: Currency;
  revsharePercentages?: { [key in VerticalType]?: number };
  spendPercentage?: number;
  type: "partner" | "network";
  verticals: IVerticalMap;
}

const AgreementDetailsCard = ({
  actionType,
  commissionType,
  cpaAmount,
  currency,
  revsharePercentages,
  spendPercentage,
  type,
  verticals
}: IAgreementDetailsCard) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography align="center" variant="h6">
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Commission Type</strong>
            </TableCell>
            <TableCell>{commissionType}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <strong>Currency</strong>
            </TableCell>
            <TableCell>{currency}</TableCell>
          </TableRow>

          {commissionType &&
            commissionType !== CommissionType.REVSHARE &&
            commissionType !== CommissionType.SPEND && (
              <>
                <TableRow>
                  <TableCell>
                    <strong>CPA Action Type</strong>
                  </TableCell>
                  <TableCell>{actionType}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <strong>CPA Base Amount</strong>
                  </TableCell>
                  <TableCell>{`${getCurrencySymbol(
                    currency
                  )}${cpaAmount}`}</TableCell>
                </TableRow>
              </>
            )}

          {commissionType &&
            commissionType !== CommissionType.CPA &&
            commissionType !== CommissionType.SPEND &&
            (Object.keys(revsharePercentages!).length > 1 ? (
              <>
                <TableRow>
                  <TableCell className={classes.revshareHeader}>
                    <strong>Vertical</strong>
                  </TableCell>
                  <TableCell className={classes.revshareHeader}>
                    <strong>RS%</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.revshare}>Default</TableCell>
                  <TableCell className={classes.revshare}>{`${
                    revsharePercentages!.default
                  }%`}</TableCell>
                </TableRow>
                {Object.entries(verticals)
                  .filter(v => revsharePercentages![v[1]])
                  .map(v => (
                    <TableRow key={v[1]}>
                      <TableCell className={classes.revshare}>{v[0]}</TableCell>
                      <TableCell className={classes.revshare}>{`${
                        revsharePercentages![v[1]]
                      }%`}</TableCell>
                    </TableRow>
                  ))}
              </>
            ) : (
              <TableRow>
                <TableCell className={classes.revshareHeader}>
                  <strong>Revshare</strong>
                </TableCell>
                <TableCell className={classes.revshareHeader}>
                  {`${revsharePercentages!.default}%`}
                </TableCell>
              </TableRow>
            ))}

          {commissionType && commissionType === CommissionType.SPEND && (
            <TableRow>
              <TableCell>
                <strong>Spend Percentage</strong>
              </TableCell>
              <TableCell>{spendPercentage}%</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    margin: spacing(),
    padding: spacing(2)
  },
  popover: {
    pointerEvents: "none"
  },
  revshare: {
    border: "none",
    padding: spacing(1, 2)
  },
  revshareHeader: {
    borderBottom: "none",
    paddingBottom: spacing()
  }
}));

export default AgreementDetailsCard;
