import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";

import {
  DeleteWebhook,
  DeleteWebhookVariables
} from "../../generated/DeleteWebhook";
import { Webhooks_webhooks_edges_node } from "../../generated/Webhooks";
import DeleteWebhookMutation from "../../mutations/DeleteWebhookMutation";

import ConfirmationAlert from "../ConfirmationAlert";
import WebhookForm from "../WebhookForm";

interface IWebhookListProps {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  offerId: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  webhooks: Webhooks_webhooks_edges_node[];
}

const WebhookList = ({
  hasNextPage,
  hasPreviousPage,
  offerId,
  onNextPage,
  onPreviousPage,
  webhooks
}: IWebhookListProps) => {
  const classes = useStyles();

  const [webhookDelete, setWebhookDelete] = useState<
    Webhooks_webhooks_edges_node
  >();
  const [webhookUpdate, setWebhookUpdate] = useState<
    Webhooks_webhooks_edges_node
  >();

  const [webhookFormOpen, setWebhookFormOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [deleteWebhook, { loading }] = useMutation<
    DeleteWebhook,
    DeleteWebhookVariables
  >(DeleteWebhookMutation);

  const onUpdateWebhook = (webhook: Webhooks_webhooks_edges_node) => {
    setWebhookFormOpen(true);
    setWebhookUpdate(webhook);
  };

  const onDeleteWebhook = (webhook: Webhooks_webhooks_edges_node) => {
    setWebhookDelete(webhook);
    setConfirmOpen(true);
  };

  const handleDeleteWebhook = () => {
    setConfirmOpen(false);

    deleteWebhook({
      awaitRefetchQueries: true,
      refetchQueries: ["Webhooks"],
      variables: { webhookId: webhookDelete!.id }
    }).then(() => setWebhookDelete(undefined));
  };

  const resetWebhookUpdate = () => {
    if (webhookUpdate) {
      setWebhookUpdate(undefined);
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h5">Pixels</Typography>

          <Button
            color="primary"
            onClick={() => setWebhookFormOpen(true)}
            variant="contained"
          >
            <AddIcon className={classes.buttonIcon} />
            Pixel
          </Button>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pixel ID</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>HTTP Method</TableCell>
              <TableCell>Url</TableCell>
              <TableCell className={classes.id}>Partner ID</TableCell>
              <TableCell>Partner</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {webhooks.map(webhook => (
              <TableRow key={webhook.id}>
                <TableCell className={classes.id}>{webhook.id}</TableCell>
                <TableCell className={classes.webhookActionType}>
                  {webhook.actionType}
                </TableCell>
                <TableCell>{webhook.httpMethod}</TableCell>
                <TableCell className={classes.webhookUrl}>
                  {webhook.url}
                </TableCell>
                <TableCell>{webhook.user.displayId}</TableCell>
                <TableCell>{webhook.user.name}</TableCell>
                <TableCell>
                  <IconButton
                    disabled={loading}
                    onClick={() => onUpdateWebhook(webhook)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    disabled={loading}
                    onClick={() => onDeleteWebhook(webhook)}
                  >
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell className={classes.paginationTableCell} colSpan={1000}>
                <Toolbar className={classes.toolbar}>
                  <ButtonGroup>
                    <Button
                      disabled={!hasPreviousPage}
                      onClick={onPreviousPage}
                    >
                      {"<"}
                    </Button>
                    <Button disabled={!hasNextPage} onClick={onNextPage}>
                      {">"}
                    </Button>
                  </ButtonGroup>
                </Toolbar>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>

      <WebhookForm
        onClose={() => setWebhookFormOpen(false)}
        onExited={resetWebhookUpdate}
        offerId={offerId}
        open={webhookFormOpen}
        type={webhookUpdate ? "Update" : "Create"}
        updateWebhookActionType={webhookUpdate?.actionType}
        updateWebhookHttpBody={webhookUpdate?.httpBody}
        updateWebhookHttpMethod={webhookUpdate?.httpMethod}
        updateWebhookId={webhookUpdate?.id}
        updateWebhookUrl={webhookUpdate?.url}
        updateWebhookUser={webhookUpdate?.user}
      />

      {webhookDelete && (
        <ConfirmationAlert
          content={webhookDelete.url}
          onNegative={() => setConfirmOpen(false)}
          onPositive={handleDeleteWebhook}
          open={confirmOpen}
          positiveAction="Delete"
          title="Delete Pixel Confirm"
        />
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: {
    marginRight: spacing()
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  id: {
    whiteSpace: "nowrap"
  },
  paginationTableCell: {
    padding: 0
  },
  paper: {
    padding: spacing(2),
    paddingBottom: 0
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  },
  webhookActionType: {
    maxWidth: 130
  },
  webhookUrl: {
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

export default WebhookList;
