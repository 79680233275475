import { ApolloQueryResult, useMutation, useQuery } from "@apollo/client";
import { format } from "date-fns";
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import { Accounts as IAccountsQuery } from "../../generated/Accounts";
import {
  AssignSubagreement as IAssignSubagreementQuery,
  AssignSubagreementVariables,
} from "../../generated/AssignSubagreement";
import {
  ActionType,
  AgreementTierType,
  CommissionType,
  CreateQualificationInput,
  Currency,
  PayType,
  QualificationType,
  QualificationWindowType,
  ThresholdType,
  TierWindowType,
  VerticalType,
} from "../../generated/globalTypes";
import { Offer_offer_offer_defaultAgreement } from "../../generated/Offer";
import {
  Subagreements as ISubagreementsQuery,
  Subagreements_subagreements_edges_node_qualifications,
} from "../../generated/Subagreements";

import AssignSubagreementMutation from "../../mutations/AssignSubagreementMutation";
import AccountsQuery from "../../queries/AccountsQuery";

import { muiDatetimeFormatter } from "../../utils/muiDatetimeFormatter";
import { verticalsMap } from "../../utils/verticalsMap";
import {
  IQualificationState,
  ITierState,
} from "../AgreementForm/AgreementForm";
import ConfirmationAlert from "../ConfirmationAlert";
import InputUserSearch from "../InputUserSearch";

interface ISubagreementFormProps {
  accountId?: number;
  accountPoolId?: number;
  affiliateDetails?: IAgreementDetailsState;
  defaultAgreement?: Offer_offer_offer_defaultAgreement;
  networkDetails?: IAgreementDetailsState | null;
  networkTiers?: ITierState[];
  offerActive: boolean;
  offerId: number;
  onClose: () => void;
  onExited: () => void;
  open: boolean;
  partnerTiers?: ITierState[];
  qualifications?: Subagreements_subagreements_edges_node_qualifications[];
  refetch: () => Promise<ApolloQueryResult<ISubagreementsQuery>>;
  ringfenceRevshare?: boolean;
  startAt?: string;
  endAt?: string;
  user?: {
    id: string;
    name: string;
  };
}

interface IAgreementDetailsState {
  actionType?: ActionType;
  commissionType?: CommissionType;
  cpaAmount?: number;
  currency?: Currency;
  revsharePercentages?: { [key in VerticalType]?: number };
  spendAccessToken?: string;
  spendPercentage?: number;
  tierType?: AgreementTierType | null;
}

const SubagreementForm = ({
  accountId,
  accountPoolId,
  affiliateDetails,
  defaultAgreement,
  networkDetails,
  networkTiers,
  offerActive,
  offerId,
  onClose,
  onExited,
  open,
  partnerTiers,
  qualifications,
  refetch,
  ringfenceRevshare,
  startAt,
  endAt,
  user,
}: ISubagreementFormProps) => {
  const emptyStringValue = undefined;

  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const updateNetworkDetails = hasPermission(
    "manage:agreements:network_details"
  );
  const enableStartEndDates = hasPermission("manage:agreements:dates");

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [updateConfirmOpen, setUpdateConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [affiliateOverride, setAffiliateOverride] = useState(
    affiliateDetails !== undefined
  );
  const [networkOverride, setNetworkOverride] = useState(
    networkDetails !== undefined
  );

  const [startState, setStartState] = useState(muiDatetimeFormatter(startAt));
  const [endState, setEndState] = useState(muiDatetimeFormatter(endAt));

  const qualificationsOverrideCheck = useCallback(() => {
    if (qualifications?.length !== defaultAgreement?.qualifications.length) {
      return true;
    }

    for (const qualification of qualifications!) {
      if (
        !defaultAgreement?.qualifications.find(
          (q) =>
            q.amount === qualification.amount &&
            q.qualificationType === qualification.qualificationType &&
            q.windowType === qualification.windowType
        )
      ) {
        return true;
      }
    }

    return false;
  }, [defaultAgreement, qualifications]);

  const tiersOverrideCheck = useCallback(
    (tierType: PayType) => {
      const subagreementTiers =
        tierType === PayType.NETWORK ? networkTiers : partnerTiers;
      const defaultTiers =
        tierType === PayType.NETWORK
          ? defaultAgreement?.networkTiers
          : defaultAgreement?.partnerTiers;

      if (tierType === PayType.NETWORK && networkDetails?.tierType !== defaultAgreement?.networkDetails?.tierType) {
        return true;
      }

      if (tierType === PayType.PARTNER && affiliateDetails?.tierType !== defaultAgreement?.affiliateDetails?.tierType) {
        return true;
      }

      if (subagreementTiers?.length !== defaultTiers?.length) {
        return true;
      }

      for (const tier of subagreementTiers!) {
        if (
          !defaultTiers?.find(
            (t) =>
              t.thresholdType === tier.thresholdType &&
              t.threshold === tier.threshold &&
              t.cpa === tier.cpa &&
              t.revsharePercentages.default ===
                tier.revsharePercentages?.default &&
              t.windowType === tier.windowType
          )
        ) {
          return true;
        }
      }
      return false;
    },
    [
      affiliateDetails?.tierType,
      defaultAgreement?.affiliateDetails?.tierType,
      defaultAgreement?.networkDetails?.tierType,
      defaultAgreement?.networkTiers,
      defaultAgreement?.partnerTiers,
      networkDetails?.tierType,
      networkTiers,
      partnerTiers
    ]
  );

  const [qualificationsOverride, setQualificationsOverride] = useState(
    !!qualifications && qualificationsOverrideCheck()
  );
  const [networkTiersOverride, setNetworkTiersOverride] = useState(
    !!networkTiers && tiersOverrideCheck(PayType.NETWORK)
  );
  const [partnerTiersOverride, setPartnerTiersOverride] = useState(
    !!partnerTiers && tiersOverrideCheck(PayType.PARTNER)
  );

  const [userIdState, setUserId] = useState<string | undefined>(
    user ? user.id : undefined
  );
  const [accountIdState, setAccountId] = useState(accountId);
  const [affiliateDetailsState, setAffiliateDetails] = useState<
    IAgreementDetailsState
  >(affiliateDetails || {});
  const [networkDetailsState, setNetworkDetails] = useState<
    IAgreementDetailsState
  >(networkDetails || {});

  const [
    partnerMultiProductRevshareState,
    setPartnerMultiProductRevshare,
  ] = useState(
    affiliateDetails?.revsharePercentages
      ? Object.keys(affiliateDetails?.revsharePercentages).length > 1
      : false
  );
  const [
    networkMultiProductRevshareState,
    setNetworkMultiProductRevshare,
  ] = useState(
    networkDetails?.revsharePercentages
      ? Object.keys(networkDetails?.revsharePercentages).length > 1
      : false
  );
  const [qualificationsState, setQualifications] = useState<
    IQualificationState[]
  >(
    qualifications ||
      (defaultAgreement?.qualifications as IQualificationState[])
  );

  const [networkTiersState, setNetworkTiers] = useState(networkTiers || []);
  const [partnerTiersState, setPartnerTiers] = useState(partnerTiers || []);

  const [ringfenceRevshareState, setRingfenceRevshareState] = useState(
    ringfenceRevshare
  );

  const { data: accountsData, loading: accountsLoading } = useQuery<
    IAccountsQuery
  >(AccountsQuery, {
    skip: !open || !accountPoolId || !userIdState,
    variables: { accountPoolId, userId: userIdState },
  });

  const [assignSubagreement, { loading }] = useMutation<
    IAssignSubagreementQuery,
    AssignSubagreementVariables
  >(AssignSubagreementMutation, {
    errorPolicy: "all",
  });

  useEffect(() => {
    setAffiliateOverride(affiliateDetails !== undefined);
    setNetworkOverride(networkDetails !== undefined);
    setQualificationsOverride(
      !!qualifications && qualificationsOverrideCheck()
    );
    setNetworkTiersOverride(
      !!networkTiers && tiersOverrideCheck(PayType.NETWORK)
    );
    setPartnerTiersOverride(
      !!partnerTiers && tiersOverrideCheck(PayType.PARTNER)
    );
    setUserId(user ? user.id : undefined);
    setAccountId(accountId);
    setAffiliateDetails(affiliateDetails || {});
    setNetworkDetails(networkDetails || {});
    setPartnerMultiProductRevshare(
      affiliateDetails?.revsharePercentages
        ? Object.keys(affiliateDetails?.revsharePercentages).length > 1
        : false
    );
    setNetworkMultiProductRevshare(
      networkDetails?.revsharePercentages
        ? Object.keys(networkDetails?.revsharePercentages).length > 1
        : false
    );
    setQualifications(
      qualifications ||
        (defaultAgreement?.qualifications as IQualificationState[])
    );
    setNetworkTiers(
      networkTiers || (defaultAgreement?.networkTiers as ITierState[])
    );
    setPartnerTiers(
      partnerTiers || (defaultAgreement?.partnerTiers as ITierState[])
    );
    setRingfenceRevshareState(ringfenceRevshare || false);
    setStartState(muiDatetimeFormatter(startAt));
    setEndState(muiDatetimeFormatter(endAt));
  }, [
    accountId,
    affiliateDetails,
    defaultAgreement,
    networkDetails,
    networkTiers,
    partnerTiers,
    qualifications,
    qualificationsOverrideCheck,
    tiersOverrideCheck,
    user,
    ringfenceRevshare,
    startAt,
    endAt,
  ]);

  const handleSelect = (selectedUserId?: string) => {
    setUserId(selectedUserId);
    setDirty(true);
  };

  const handleAccountSelect = (selectedAccountId: string) => {
    const accountIdNumber = parseInt(selectedAccountId, 10);
    setAccountId(accountIdNumber);
    setDirty(true);
  };

  const handleQualificationOverrideSwitchChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setQualificationsOverride(event.target.checked);
    if (event.target.checked && !qualificationsState?.length) {
      handleAddQualification();
    }
  };

  const handleQualificationChange = (
    event: ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    setDirty(true);
    const newQualifications = qualificationsState.map((qualification) => ({
      ...qualification,
    }));
    const nameSplit = event.target.name!.split("-");
    Object.assign(newQualifications[parseInt(nameSplit[1], 10)], {
      [nameSplit[0]]:
        nameSplit[0] === "amount"
          ? parseFloat(event.target.value as string)
          : event.target.value,
    });
    setQualifications(newQualifications);
  };

  const handleAddQualification = () => {
    if (qualificationsState.length >= Object.keys(QualificationType).length) {
      return;
    }
    setQualifications(qualificationsState.concat([{}]));
  };

  const handleRemoveQualification = () => {
    setDirty(true);
    setQualifications(
      qualificationsState.slice(0, qualificationsState.length - 1)
    );
  };

  const handleTierSwitchChange = (
    event: ChangeEvent<HTMLInputElement>,
    payType: PayType
  ) => {
    setDirty(true);
    const tiers =
      payType === PayType.NETWORK ? networkTiersState : partnerTiersState;
    payType === PayType.NETWORK
      ? setNetworkTiersOverride(event.target.checked)
      : setPartnerTiersOverride(event.target.checked);
    if (event.target.checked && !tiers.length) {
      handleAddTier(payType);
    }
  };

  const handleAgreementTierChange = (
    event: ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >, payType: PayType
  ) => {
    const newTier: ITierState = { payType };
    if (payType === PayType.NETWORK) {
      setNetworkTiers([newTier])
      setNetworkDetails({
        ...networkDetailsState,
        tierType: event.target.value as AgreementTierType,
      })
    } else {
      setPartnerTiers([newTier]);
      setAffiliateDetails({
        ...affiliateDetailsState,
        tierType: event.target.value as AgreementTierType,
      })
    }
  }

  const handleAddTier = (payType: PayType) => {
    const tiersState =
      payType === PayType.NETWORK ? networkTiersState : partnerTiersState;
    const newTier: ITierState = {
      payType,
      thresholdType:
        tiersState.length >= 1 ? tiersState[0].thresholdType : undefined,
      windowType: tiersState.length >= 1 ? tiersState[0].windowType : undefined,
    };

    payType === PayType.NETWORK
      ? setNetworkTiers(networkTiersState.concat([newTier]))
      : setPartnerTiers(partnerTiersState.concat([newTier]));
  };

  const handleRemoveTier = (payType: PayType) => {
    setDirty(true);

    const tiersSetFunction =
      payType === PayType.NETWORK ? setNetworkTiers : setPartnerTiers;
    const tiersState =
      payType === PayType.NETWORK ? networkTiersState : partnerTiersState;

    tiersSetFunction(tiersState.slice(0, tiersState.length - 1));
  };

  const handleTierChange = (
    event: ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
    payType: PayType
  ) => {
    setDirty(true);

    const newTiers = (payType === PayType.NETWORK
      ? networkTiersState
      : partnerTiersState
    ).map((tier) => ({
      ...tier,
    }));

    const nameSplit = event.target.name!.split("-");
    Object.assign(
      newTiers[parseInt(nameSplit[1], 10)],
      nameSplit[0] === "revsharePercentages"
        ? {
            revsharePercentages: {
              default: parseFloat(event.target.value as string),
            },
          }
        : {
            [nameSplit[0]]:
              nameSplit[0] === "threshold" || nameSplit[0] === "cpa"
                ? parseFloat(event.target.value as string)
                : event.target.value,
          }
    );

    if (["thresholdType", "windowType"].includes(nameSplit[0])) {
      newTiers.map((tier) => {
        Object.assign(tier, { [nameSplit[0]]: event.target.value })
      })

      if (nameSplit[0] === "thresholdType") {
        if (![ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(event.target.value as ThresholdType)) {
          newTiers.map((tier) => {
            Object.assign(tier, { windowType: undefined })
          })
        }
      }
    }

    payType === PayType.NETWORK
      ? setNetworkTiers(newTiers)
      : setPartnerTiers(newTiers);
  };

  const handleRingfenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setRingfenceRevshareState(event.target.checked);
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    setAffiliateOverride(affiliateDetails !== undefined);
    setNetworkOverride(networkDetails !== undefined);
    setQualificationsOverride(
      !!qualifications && qualificationsOverrideCheck()
    );
    setNetworkTiersOverride(
      !!networkTiers && tiersOverrideCheck(PayType.NETWORK)
    );
    setPartnerTiersOverride(
      !!partnerTiers && tiersOverrideCheck(PayType.PARTNER)
    );
    setUserId(user ? user.id : undefined);
    setAccountId(accountId);
    setAffiliateDetails(affiliateDetails || {});
    setNetworkDetails(networkDetails || {});
    setPartnerMultiProductRevshare(
      affiliateDetails?.revsharePercentages
        ? Object.keys(affiliateDetails?.revsharePercentages).length > 1
        : false
    );
    setNetworkMultiProductRevshare(
      networkDetails?.revsharePercentages
        ? Object.keys(networkDetails?.revsharePercentages).length > 1
        : false
    );
    setQualifications(
      qualifications ||
        (defaultAgreement?.qualifications as IQualificationState[])
    );
    setNetworkTiers(
      networkTiers || (defaultAgreement?.networkTiers as ITierState[])
    );
    setPartnerTiers(
      partnerTiers || (defaultAgreement?.partnerTiers as ITierState[])
    );
    setRingfenceRevshareState(ringfenceRevshare || false);
    setStartState(muiDatetimeFormatter(startAt));
    setEndState(muiDatetimeFormatter(endAt));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    offerActive ? onSubmit() : setUpdateConfirmOpen(true);
  };

  const onSubmit = () => {
    const variables: AssignSubagreementVariables = {
      accountId: accountIdState,
      endAt: endState ? new Date(endState).toISOString() : endState,
      offerId,
      ringfenceRevshare: ringfenceRevshareState,
      startAt: startState ? new Date(startState).toISOString() : startState,
      userId: userIdState!,
    };

    if (affiliateOverride || partnerTiersOverride) {
      const defaultCast = defaultAgreement?.affiliateDetails as any;
      variables.affiliateDetails = {
        actionType: affiliateOverride ? affiliateDetailsState.actionType : defaultCast.actionType,
        commissionType: affiliateOverride ? affiliateDetailsState.commissionType! : defaultCast.commissionType,
        cpaAmount:
          affiliateDetailsState.commissionType === CommissionType.REVSHARE
            ? undefined
            : affiliateOverride ? affiliateDetailsState.cpaAmount : defaultCast.cpaAmount,
        currency: affiliateOverride ? affiliateDetailsState.currency! : defaultCast.currency,
        revsharePercentages:
          affiliateDetailsState.commissionType === CommissionType.CPA
            ? undefined
            : affiliateOverride ? affiliateDetailsState.revsharePercentages : defaultCast.revsharePercentages,
        spendAccessToken: affiliateDetailsState.spendAccessToken,
        spendPercentage: affiliateDetailsState.spendPercentage,
        tierType: partnerTiersOverride ? affiliateDetailsState.tierType : undefined,
      };
    }

    if (networkOverride || networkTiersOverride) {
      const defaultCast = defaultAgreement?.networkDetails as any;
      variables.networkDetails = {
        actionType: networkOverride ? networkDetailsState.actionType : defaultCast.actionType,
        commissionType: networkOverride ? networkDetailsState.commissionType! : defaultCast.commissionType,
        cpaAmount:
          networkDetailsState.commissionType === CommissionType.REVSHARE
            ? undefined
            : networkOverride ? networkDetailsState.cpaAmount : defaultCast.cpaAmount,
        currency: networkOverride ? networkDetailsState.currency! : defaultCast.currency,
        revsharePercentages:
          networkDetailsState.commissionType === CommissionType.CPA
            ? undefined
            : networkOverride ? networkDetailsState.revsharePercentages : defaultCast.revsharePercentages,
        tierType: networkTiersOverride ? networkDetailsState.tierType : undefined,
      };
    }

    if (qualificationsOverride) {
      variables.qualifications = qualificationsState.map((q) => ({
        amount: q.amount,
        qualificationType: q.qualificationType,
        windowType: q.qualificationType === QualificationType.DEPOSIT_WINDOW ? q.windowType : undefined,
      })) as CreateQualificationInput[];
    }

    if (networkTiersOverride) {
      variables.networkTiers = networkTiersState.map((tier) => ({
        cpa: tier.cpa,
        payType: PayType.NETWORK,
        revsharePercentages: tier.revsharePercentages,
        threshold: tier.threshold!,
        thresholdType: tier.thresholdType!,
        windowType: [ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(tier.thresholdType!) ? tier.windowType : undefined
      }));
    }
    if (partnerTiersOverride) {
      variables.partnerTiers = partnerTiersState.map((tier) => ({
        cpa: tier.cpa,
        payType: PayType.PARTNER,
        revsharePercentages: tier.revsharePercentages,
        threshold: tier.threshold!,
        thresholdType: tier.thresholdType!,
        windowType: [ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(tier.thresholdType!) ? tier.windowType : undefined
      }));
    }

    assignSubagreement({
      variables,
    }).then(async () => {
      setDirty(false);
      if (!user) {
        await refetch();
      }
      onClose();
    });
  };

  const renderAgreementDetails = (type: "affiliate" | "network") => {
    const state =
      type === "affiliate" ? affiliateDetailsState : networkDetailsState;
    const setFunction =
      type === "affiliate" ? setAffiliateDetails : setNetworkDetails;

    const checked =
      type === "affiliate"
        ? partnerMultiProductRevshareState
        : networkMultiProductRevshareState;
    const checkedSetFunction =
      type === "affiliate"
        ? setPartnerMultiProductRevshare
        : setNetworkMultiProductRevshare;

    const commissionTypeOptions = Object.keys(CommissionType).filter(
      (commissionType) => commissionType !== "SPEND"
    );

    const setInputState = (agreementDetails: IAgreementDetailsState) => {
      setDirty(true);
      setFunction({ ...state, ...agreementDetails });
      if (agreementDetails.commissionType === CommissionType.CLAWBACK) {
        if (type === "affiliate") setPartnerTiersOverride(false);
        if (type === "network") setNetworkTiersOverride(false);
      }
    };

    const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        setInputState(
          state.revsharePercentages?.default ||
            state.revsharePercentages?.default === 0
            ? {
                revsharePercentages: {
                  [VerticalType.default]: state.revsharePercentages.default,
                },
              }
            : {}
        );
      }
      checkedSetFunction(event.target.checked);
    };

    return (
      <>
        <FormControl className={classes.formControl} fullWidth required>
          <InputLabel htmlFor={`${type}-commission-type`}>
            Commission Type
          </InputLabel>
          <Select
            inputProps={{ id: `${type}-commission-type` }}
            native
            onChange={(event) =>
              setInputState({
                commissionType: event.target.value as CommissionType,
              })
            }
            required
            value={state.commissionType}
          >
            <option value="" />
            {commissionTypeOptions.map((commissionType) => (
              <option key={commissionType} value={commissionType}>
                {commissionType}
              </option>
            ))}
          </Select>
        </FormControl>

        {state.commissionType &&
          state.commissionType !== CommissionType.REVSHARE &&
          state.commissionType !== CommissionType.SPEND && (
            <>
              <FormControl className={classes.formControl} fullWidth required>
                <InputLabel htmlFor={`${type}-action-type`}>
                  Action Type
                </InputLabel>
                <Select
                  inputProps={{ id: `${type}-action-type` }}
                  native
                  onChange={(event) =>
                    setInputState({
                      actionType: event.target.value as ActionType,
                    })
                  }
                  required
                  value={state.actionType}
                >
                  <option value="" />
                  {Object.keys(ActionType).map((actionType) => (
                    <option key={actionType} value={actionType}>
                      {actionType}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <TextField
                className={classes.formControl}
                id={`${type}-cpa-amount`}
                inputProps={{ min: 0, step: 0.01 }}
                fullWidth
                label="CPA Amount"
                onChange={(event) =>
                  setInputState({
                    cpaAmount: parseFloat(event.target.value),
                  })
                }
                required
                type="number"
                value={
                  state.cpaAmount === 0 || state.cpaAmount
                    ? state.cpaAmount
                    : ""
                }
              />
            </>
          )}

        {state.commissionType &&
          state.commissionType !== CommissionType.CPA &&
          state.commissionType !== CommissionType.SPEND && (
            <>
              <div className={classes.formGroup}>
                <TextField
                  className={`${classes.formControl} ${classes.formRevshare}`}
                  id={`${type}-default-revshare-percentage`}
                  inputProps={{ max: 100, min: 0, step: 0.01 }}
                  label="Default Revshare Percentage"
                  onChange={(event) =>
                    setInputState({
                      revsharePercentages:
                        typeof parseFloat(event.target.value) === "number"
                          ? {
                              ...state.revsharePercentages,
                              [VerticalType.default]: parseFloat(
                                event.target.value
                              ),
                            }
                          : emptyStringValue,
                    })
                  }
                  required
                  type="number"
                  value={
                    typeof state.revsharePercentages?.default === "number"
                      ? state.revsharePercentages.default
                      : ""
                  }
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={checked}
                      onChange={handleSwitch}
                      name="Use multi product revshare"
                    />
                  }
                  label="Multi-product"
                  labelPlacement="start"
                />
              </div>

              {checked &&
                Object.entries(verticalsMap).map((vertical) => (
                  <TextField
                    className={classes.formControl}
                    id={`${type}-${vertical[1]}-revshare-percentage`}
                    inputProps={{ max: 100, min: 0, step: 0.01 }}
                    key={vertical[1]}
                    fullWidth
                    label={`${vertical[0]} Revshare Percentage`}
                    onChange={(event) => {
                      if (event.target.value === "") {
                        const revsharePercentages: {
                          [key in VerticalType]?: number;
                        } = {
                          ...state.revsharePercentages,
                        };
                        delete revsharePercentages[vertical[1]];
                        setInputState({ revsharePercentages });
                      } else {
                        setInputState({
                          revsharePercentages: {
                            ...state.revsharePercentages,
                            [vertical[1] as VerticalType]: parseFloat(
                              event.target.value
                            ),
                          },
                        });
                      }
                    }}
                    type="number"
                    value={
                      state.revsharePercentages &&
                      typeof state.revsharePercentages[vertical[1]] === "number"
                        ? state.revsharePercentages[vertical[1]]
                        : ""
                    }
                  />
                ))}
            </>
          )}

        {state.commissionType && state.commissionType === CommissionType.SPEND && (
          <>
            <TextField
              className={classes.formControl}
              id={`${type}-spend-access-token`}
              fullWidth
              label="Access Token"
              onChange={(event) =>
                setInputState({
                  spendAccessToken: event.target.value || emptyStringValue,
                })
              }
              required
              value={state.spendAccessToken || ""}
            />

            <TextField
              className={classes.formControl}
              id={`${type}-spend-percentage`}
              inputProps={{ max: 100, min: 0, step: 0.01 }}
              fullWidth
              label="Spend Percentage"
              onChange={(event) =>
                setInputState({
                  spendPercentage: parseFloat(event.target.value),
                })
              }
              required
              type="number"
              value={
                state.spendPercentage === 0 || state.spendPercentage
                  ? state.spendPercentage
                  : ""
              }
            />
          </>
        )}

        <FormControl className={classes.formControl} fullWidth required>
          <InputLabel htmlFor={`${type}-currency`}>Currency</InputLabel>
          <Select
            inputProps={{ id: `${type}-currency` }}
            native
            onChange={(event) =>
              setInputState({ currency: event.target.value as Currency })
            }
            required
            value={state.currency}
          >
            <option value="" />
            {Object.keys(Currency).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </Select>
        </FormControl>
        {(state.commissionType === CommissionType.HYBRID ||
          state.commissionType === CommissionType.REVSHARE) &&
          type === "affiliate" && (
            <>
              <FormGroup row>
                <Tooltip
                  arrow
                  placement="right"
                  title="If checked, a negative monthly subtotal on this agreement will be shown as a zero in the monthly payments report. If unchecked, a negative monthly total for given agreement will be included with all other partner revshare earnings"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ringfenceRevshareState}
                        onChange={handleRingfenceChange}
                        name="ringfenceRevshare"
                      />
                    }
                    label="Ringfence Negative Revshare"
                  />
                </Tooltip>
              </FormGroup>
            </>
          )}
      </>
    );
  };

  const renderQualifications = () => {
    return qualificationsState.map((qualification, index) => (
      <div key={index}>
        <FormControl className={classes.formControl} fullWidth required>
          <InputLabel id={`qualification-${index}`}>Qualification Action</InputLabel>
          <Select
            input={<Input />}
            inputProps={{ name: `qualificationType-${index}` }}
            labelId={`qualification-${index}`}
            onChange={handleQualificationChange}
            required
            value={qualification.qualificationType || ""}
          >
            {Object.values(QualificationType).map((qualificationType) => {
              const qualificationIndex = qualificationsState.findIndex(
                (q) => q.qualificationType === qualificationType
              );
              if (qualificationIndex < 0 || qualificationIndex === index) {
                let menuText: string = "";
                switch (qualificationType) {
                  case "MIN_DEPOSIT":
                    menuText = "Min Deposit Amount (Cumulative)";
                    break;
                  case "MIN_WAGER_AMOUNT":
                    menuText = "Min Wager Amount (Cumulative)";
                    break;
                  case "MIN_WAGER_COUNT":
                    menuText = "Min Wager Count (Cumulative)";
                    break;
                  case "DEPOSIT_WINDOW":
                    menuText = "Max Deposit Window";
                    break;
                }
                return (
                  <MenuItem key={qualificationType} value={qualificationType}>
                    {menuText}
                  </MenuItem>
                );
              } else {
                return undefined;
              }
            })}
          </Select>
        </FormControl>

        {qualification.qualificationType === QualificationType.DEPOSIT_WINDOW && <FormControl className={classes.formControl} fullWidth required>
          <InputLabel htmlFor={`windowType-${index}`}>
            Window Event Type
          </InputLabel>
          <Select
            input={<Input />}
            inputProps={{ name: `windowType-${index}` }}
            labelId={`qualification-${index}`}
            onChange={handleQualificationChange}
            required
            value={qualification.windowType || ""}
          >
            {Object.keys(QualificationWindowType).map((windowType) => (
              <MenuItem key={windowType} value={windowType}>
                {windowType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}

        <TextField
          className={classes.formControl}
          fullWidth
          inputProps={{ name: `amount-${index}`, min: 0, step: qualification.qualificationType === QualificationType.DEPOSIT_WINDOW ? 1 : 0.01 }}
          label="Amount"
          onChange={handleQualificationChange}
          required
          type="number"
          value={
            qualification.amount === 0 || qualification.amount
              ? qualification.amount
              : ""
          }
        />
      </div>
    ));
  };

  const renderTiers = (payType: PayType, agreementTierType: AgreementTierType) => {
    const agreementState =
      payType === PayType.NETWORK ? networkDetailsState : affiliateDetailsState;
    const tiersState =
      payType === PayType.NETWORK ? networkTiersState : partnerTiersState;

    const subagreementThesholdTypes = {
      FIRST_TIME_DEPOSIT: ThresholdType.FIRST_TIME_DEPOSIT,
      QUALIFIED_DEPOSIT: ThresholdType.QUALIFIED_DEPOSIT,
      NET_REVENUE: ThresholdType.NET_REVENUE,
    }
    const clickThresholdTypes = {
      DEPOSIT_AMOUNT: ThresholdType.DEPOSIT_AMOUNT,
      DEPOSIT_COUNT: ThresholdType.DEPOSIT_COUNT,
      DEPOSIT_WINDOW: ThresholdType.DEPOSIT_WINDOW,
      WAGER_AMOUNT: ThresholdType.WAGER_AMOUNT,
      WAGER_COUNT: ThresholdType.WAGER_COUNT,
      WAGER_WINDOW: ThresholdType.WAGER_WINDOW,
    }

    return tiersState.map((tier, index) => (
      <div key={index}>
        <Typography>{`${payType} Tier ${index + 1}`}</Typography>

        <FormControl
          className={classes.formControl}
          disabled={index >= 1}
          required
        >
          <InputLabel htmlFor={`${payType}-tier-${index + 1}-threshold-type`}>
            Threshold Type
          </InputLabel>
          <Select
            inputProps={{
              id: `${payType}-tier-${index + 1}-threshold-type`,
              name: `thresholdType-${index}`,
            }}
            native
            onChange={(event) => handleTierChange(event, payType)}
            value={tier.thresholdType || ""}
          >
            <option value="" />
            {Object.keys(agreementTierType === AgreementTierType.SUBAGREEMENT ? subagreementThesholdTypes : clickThresholdTypes).map((thresholdType) => (
              <option key={thresholdType} value={thresholdType}>
                {thresholdType.replace(/_/g, " ")}
              </option>
            ))}
          </Select>
        </FormControl>

        {[ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(tier.thresholdType!) && (
          <FormControl className={classes.formControl} disabled={index >= 1} fullWidth required>
            <InputLabel htmlFor={`${payType}-tier-${index + 1}-window-type`}>
              Window Event Type
            </InputLabel>
            <Select
              inputProps={{
                id: `${payType}-tier-${index + 1}-window-type`,
                name: `windowType-${index}`,
              }}
              native
              onChange={(event) => handleTierChange(event, payType)}
              required
              value={tier.windowType || ""}
            >
              <option value="" />
              {Object.keys(TierWindowType).map((windowType) => (
                <option key={windowType} value={windowType}>
                  {windowType.replace(/_/g, " ")}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          className={classes.formControl}
          fullWidth
          inputProps={{ name: `threshold-${index}`, min: 0, step: 0.01 }}
          label="Theshold"
          onChange={(event) => handleTierChange(event, payType)}
          required
          type="number"
          value={tier.threshold === 0 || tier.threshold ? tier.threshold : ""}
        />

        {agreementState?.commissionType !== CommissionType.REVSHARE && (
          <TextField
            className={classes.formControl}
            fullWidth
            inputProps={{ name: `cpa-${index}`, min: 0, step: 0.01 }}
            label="CPA Amount"
            onChange={(event) => handleTierChange(event, payType)}
            required={agreementState.commissionType === CommissionType.CPA}
            type="number"
            value={tier.cpa === 0 || tier.cpa ? tier.cpa : ""}
          />
        )}

        {agreementState?.commissionType !== CommissionType.CPA &&
          agreementState?.commissionType !== CommissionType.SPEND && (
            <TextField
              className={classes.formControl}
              fullWidth
              inputProps={{ name: `revsharePercentages-${index}` }}
              label="RS %"
              onChange={(event) => handleTierChange(event, payType)}
              required={
                agreementState.commissionType === CommissionType.REVSHARE
              }
              type="number"
              value={
                tier.revsharePercentages?.default ||
                tier.revsharePercentages?.default === 0
                  ? tier.revsharePercentages.default
                  : ""
              }
            />
          )}
      </div>
    ));
  };

  const dialogTitle = user ? "Update Subagreement" : "Create Subagreement";
  const dialogText = user
    ? `Change the existing agreement between ${user.name} and this offer.`
    : "Create a new subagreement between a partner and this offer.";

  return (
    <>
      <Dialog
        onClose={handleClose}
        onExited={() => {
          onCloseReset();
          onExited();
        }}
        open={open}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <FormControl disabled={loading}>
              <DialogContentText>{dialogText}</DialogContentText>

              {enableStartEndDates && (
                <>
                  <DialogContentText className={classes.dateDialog}>
                    Start Date
                  </DialogContentText>
                  <TextField
                    type="datetime-local"
                    className={classes.formControl}
                    value={startState || ""}
                    onChange={(event) => {
                      setDirty(true);

                      if (!event.target.value) {
                        setStartState(null);
                        return;
                      }

                      let chosenDate = new Date(event.target.value);
                      if (chosenDate < new Date()) {
                        chosenDate = new Date();
                      }
                      if (endState && chosenDate > new Date(endState)) {
                        setEndState(format(chosenDate, "yyyy-MM-dd'T'HH:mm"));
                      }
                      setStartState(format(chosenDate, "yyyy-MM-dd'T'HH:mm"));
                    }}
                  />
                  <DialogContentText className={classes.dateDialog}>
                    End Date
                  </DialogContentText>
                  <TextField
                    type="datetime-local"
                    className={classes.formControl}
                    value={endState || ""}
                    onChange={(event) => {
                      setDirty(true);

                      if (!event.target.value) {
                        setEndState(null);
                        return;
                      }

                      let chosenDate = new Date(event.target.value);
                      const startDate = startState
                        ? new Date(startState)
                        : new Date();
                      if (chosenDate < startDate) {
                        chosenDate = startDate;
                      }
                      setEndState(format(chosenDate, "yyyy-MM-dd'T'HH:mm"));
                    }}
                  />
                </>
              )}

              <Typography variant="h6">Partner</Typography>
              <InputUserSearch
                className={classes.formControl}
                onSelect={handleSelect}
                staticUser={user}
              />
              {accountPoolId && (
                <FormControl
                  className={classes.formControl}
                  disabled={!userIdState}
                  fullWidth
                >
                  <InputLabel htmlFor="account-id">
                    {accountsLoading ? "Loading accounts" : "Account"}
                  </InputLabel>
                  <Select
                    inputProps={{ id: "account-id" }}
                    native
                    onChange={(event) =>
                      handleAccountSelect(event.target.value as string)
                    }
                    value={accountIdState}
                  >
                    <option value="" />
                    {accountsData?.accounts?.edges.map(
                      (accountsDataAccount) => (
                        <option
                          key={accountsDataAccount.node.id}
                          value={accountsDataAccount.node.id}
                        >
                          {accountsDataAccount.node.username}
                        </option>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
              <Divider />
              {updateNetworkDetails && (
                <>
                  <div className={classes.overrideContainer}>
                    <Typography variant="h6">
                      Network Pay-In Override
                    </Typography>
                    <Switch
                      checked={networkOverride}
                      onClick={() => setNetworkOverride(!networkOverride)}
                    />
                  </div>
                  {networkOverride && renderAgreementDetails("network")}
                </>
              )}
              <div className={classes.overrideContainer}>
                <Typography variant="h6">Partner Pay-Out Override</Typography>
                <Switch
                  checked={affiliateOverride}
                  onClick={() => setAffiliateOverride(!affiliateOverride)}
                />
              </div>
              {affiliateOverride && renderAgreementDetails("affiliate")}

              <div className={classes.overrideContainer}>
                <Typography variant="h6">Qualifications Override</Typography>
                <Switch
                  checked={qualificationsOverride}
                  onChange={handleQualificationOverrideSwitchChange}
                />
              </div>
              {qualificationsOverride && (
                <>
                  {renderQualifications()}

                  <div className={classes.formButtons}>
                    <Button
                      disabled={loading || qualificationsState.length === 0}
                      onClick={handleRemoveQualification}
                    >
                      Remove Qualification
                    </Button>
                    <Button
                      disabled={loading || qualificationsState.length >= 4}
                      onClick={handleAddQualification}
                      variant="contained"
                    >
                      Add Qualification
                    </Button>
                  </div>
                </>
              )}

              {updateNetworkDetails && (
                  (!networkOverride && defaultAgreement?.networkDetails?.commissionType !== CommissionType.CLAWBACK) ||
                  (networkOverride && networkDetailsState.commissionType !== CommissionType.CLAWBACK)
                ) && (
                <div className={classes.overrideContainer}>
                  <Typography variant="h6">Network Tiers Override</Typography>
                  <Switch
                    checked={networkTiersOverride}
                    onChange={(event) =>
                      handleTierSwitchChange(event, PayType.NETWORK)
                    }
                  />
                </div>
              )}
              {networkTiersOverride && (
                <>
                  {networkTiersState.length !== 0 && (
                    <FormControl className={classes.formControl} fullWidth required>
                      <InputLabel htmlFor="network-tier-type">
                        Network Tier Grouping Type
                      </InputLabel>
                      <Select
                        inputProps={{ id: "network-tier-type" }}
                        native
                        onChange={(event) => handleAgreementTierChange(event, PayType.NETWORK)}
                        required
                        value={networkDetailsState.tierType || ""}
                      >
                        <option value="" />
                        {Object.keys(AgreementTierType).map((tierType) => (
                          <option key={tierType} value={tierType}>
                            {tierType}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {networkTiersState.length === 0 && (
                    <Typography variant="body1">No network tiers active</Typography>
                  )}

                  {renderTiers(PayType.NETWORK, networkDetailsState.tierType || AgreementTierType.SUBAGREEMENT)}{" "}

                  <div className={classes.formButtons}>
                    <Button onClick={() => handleRemoveTier(PayType.NETWORK)}>
                      Remove Tier
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={() => handleAddTier(PayType.NETWORK)}
                      variant="contained"
                    >
                      Add Tier
                    </Button>
                  </div>
                </>
              )}
              {((!affiliateOverride && defaultAgreement?.affiliateDetails?.commissionType !== CommissionType.CLAWBACK) ||
                  (affiliateOverride && affiliateDetailsState.commissionType !== CommissionType.CLAWBACK)
                ) && (
                <div className={classes.overrideContainer}>
                  <Typography variant="h6">Partner Tiers Override</Typography>
                  <Switch
                    checked={partnerTiersOverride}
                    onChange={(event) =>
                      handleTierSwitchChange(event, PayType.PARTNER)
                    }
                  />
                </div>
              )}
              {partnerTiersOverride && (
                <>
                  {partnerTiersState.length !== 0 && (
                    <FormControl className={classes.formControl} fullWidth required>
                      <InputLabel htmlFor="partner-tier-type">
                        Partner Tier Grouping Type
                      </InputLabel>
                      <Select
                        inputProps={{ id: "partner-tier-type" }}
                        native
                        onChange={(event) => handleAgreementTierChange(event, PayType.PARTNER)}
                        required
                        value={affiliateDetailsState.tierType || ""}
                      >
                        <option value="" />
                        {Object.keys(AgreementTierType).map((tierType) => (
                          <option key={tierType} value={tierType}>
                            {tierType}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {partnerTiersState.length === 0 && (
                    <Typography variant="body1">No partner tiers active</Typography>
                  )}

                  {renderTiers(PayType.PARTNER, affiliateDetailsState.tierType || AgreementTierType.SUBAGREEMENT)}

                  <div className={classes.formButtons}>
                    <Button onClick={() => handleRemoveTier(PayType.PARTNER)}>
                      Remove Tier
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={() => handleAddTier(PayType.PARTNER)}
                      variant="contained"
                    >
                      Add Tier
                    </Button>
                  </div>
                </>
              )}
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={loading || !userIdState || !dirty}
              type="submit"
              variant="contained"
            >
              {user ? "Update" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onClose();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />

      <ConfirmationAlert
        content="You are updating a subagreement within an inactive offer. Do you wish to continue?"
        onNegative={() => setUpdateConfirmOpen(false)}
        onPositive={() => {
          setUpdateConfirmOpen(false);
          onSubmit();
        }}
        open={updateConfirmOpen}
        positiveAction="Update"
        title="Update Subagreement"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  dateDialog: { margin: 0 },
  formButtons: { display: "flex", justifyContent: "flex-end" },
  formControl: { paddingBottom: spacing(2) },
  formGroup: { display: "flex" },
  formRevshare: { flex: "auto" },
  overrideContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: spacing(2),
  },
}));

export default SubagreementForm;
