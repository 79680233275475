import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import { IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";

import ConfirmationAlert from "../ConfirmationAlert";

import {
  TransactionCurrency,
  TransactionSource,
  TransactionType
} from "../../generated/globalTypes";

import getCurrencySymbol from "../../utils/getCurrencySymbol";

import RemoveTransactionMutation from "../../mutations/RemoveTransactionMutation";

interface ICLickShowListItem {
  id: string;
  currency: TransactionCurrency | null;
  transactionType: TransactionType;
  transactionAt: any;
  source: TransactionSource | null;
  amount: number | null;
  externalId: string | null;
  removed: boolean;
  updatedAt: any;
}

const ClickShowListItem = ({
  id,
  currency,
  transactionType,
  transactionAt,
  source,
  amount,
  externalId,
  removed,
  updatedAt
}: ICLickShowListItem) => {
  const classes = useStyles();

  const [mouseOver, setMouseOver] = useState(false);
  const [openConfirmationAlert, setOpenConfirmationAlert] = useState(false);

  const [removeTransaction] = useMutation(RemoveTransactionMutation);

  const transactionDate = new Date(transactionAt).toUTCString();
  const updatedAtDate = new Date(updatedAt).toUTCString();

  const confirmationTitle = "Remove Transaction";
  const confirmationContent = `Are you sure you want to remove ${transactionType} from ${transactionDate}?`;
  const confirmationAction = "Remove";

  const renderRemoveButton = () => {
    return (
      <>
        <IconButton onClick={onOpenAlert}>
          <CancelIcon className={classes.cancelIcon} />
        </IconButton>
      </>
    );
  };

  const onOpenAlert = () => {
    setOpenConfirmationAlert(true);
  };

  const onConfirmationNegative = () => {
    setOpenConfirmationAlert(false);
  };

  const onConfirmationPositive = () => {
    removeTransaction({ variables: { transactionId: id } }).then(() =>
      setOpenConfirmationAlert(false)
    );
  };
  return (
    <>
      <TableRow
        hover={removed ? false : true}
        className={removed ? classes.grayout : ""}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <TableCell className={classes.cell}>{transactionType}</TableCell>
        <TableCell className={classes.cell}>{transactionDate}</TableCell>
        <TableCell className={classes.cell}>{source || "N/A"}</TableCell>
        <TableCell className={classes.cell}>
          {amount !== null && currency
            ? `${getCurrencySymbol(currency)}${amount}`
            : "N/A"}
        </TableCell>
        <TableCell className={classes.cell}>{externalId}</TableCell>
        <TableCell className={classes.cell}>
          {removed
            ? "Removed"
            : mouseOver && transactionType !== TransactionType.CLICK
            ? renderRemoveButton()
            : ""}
        </TableCell>
        <TableCell className={classes.cell}>{updatedAtDate}</TableCell>
      </TableRow>

      <ConfirmationAlert
        content={confirmationContent}
        onNegative={onConfirmationNegative}
        onPositive={onConfirmationPositive}
        open={openConfirmationAlert}
        positiveAction={confirmationAction}
        title={confirmationTitle}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  cancelIcon: {
    "&:hover": {
      color: "#FF0000"
    }
  },
  cell: {
    height: "3rem",
    padding: "0 1rem"
  },
  grayout: {
    opacity: 0.4
  }
}));

export default ClickShowListItem;
