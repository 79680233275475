import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import {
  AppBar,
  Button,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ReplayIcon from "@material-ui/icons/Replay";

import { useAuth0 } from "../../utils/auth0Provider";

import { Brand_brand_brand_advertiser } from "../../generated/Brand";
import { DeactivateBrand } from "../../generated/DeactivateBrand";
import { ReactivateBrand } from "../../generated/ReactivateBrand";
import DeactivateBrandMutation from "../../mutations/DeactivateBrandMutation";
import ReactivateBrandMutation from "../../mutations/ReactivateBrandMutation";

import { useHistory, useParams } from "react-router-dom";
import OfferListContainer from "../../containers/OfferListContainer";
import BrandForm from "../BrandForm";
import ConfirmationAlert from "../ConfirmationAlert";

interface IBrandShowProps {
  active: boolean;
  advertiser: Brand_brand_brand_advertiser;
  id: number;
  logoBanner: string | null;
  logoIcon: string | null;
  name: string;
  trackingLink: string | null;
  trackingLinkAppend: string | null;
}

const BrandShow = ({
  active,
  advertiser,
  id,
  logoBanner,
  logoIcon,
  name,
  trackingLink,
  trackingLinkAppend
}: IBrandShowProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const updateBrands = hasPermission("manage:brands");

  const [activeConfirmationOpen, setActiveConfirmationOpen] = useState(false);

  const [deactivateBrand, { loading: deactivateLoading }] = useMutation<
    DeactivateBrand
  >(DeactivateBrandMutation, { variables: { brandId: id } });

  const [reactivateBrand, { loading: reactivateLoading }] = useMutation<
    ReactivateBrand
  >(ReactivateBrandMutation, { variables: { brandId: id } });

  const [brandUpdateOpen, setBrandUpdateOpen] = useState(false);
  const { tabId: tabIndex } = useParams<{ tabId: string }>();
  const history = useHistory();

  const onBrandActiveClick = () => {
    setActiveConfirmationOpen(true);
  };

  const onBrandUpdateClose = () => {
    setBrandUpdateOpen(false);
  };

  const onConfirmationNegative = () => {
    setActiveConfirmationOpen(false);
  };

  const onConfirmationPositive = () => {
    setActiveConfirmationOpen(false);
    active ? deactivateBrand() : reactivateBrand();
  };

  const activeConfirmationContent = active
    ? "Deactivating a brand will deactivate all offers and creatives, preventing click tracking."
    : "Reactivating a brand will allow new offers to be created.";
  const activeConfirmationPositiveAction = active ? "Deactivate" : "Reactivate";
  const activeConfirmationTitle = active
    ? `Deactivate Brand: ${name}`
    : `Reactivate Brand: ${name}`;

  return (
    <>
      <Paper>
        <div className={classes.header}>
          <Typography variant="h4">{name}</Typography>

          {updateBrands && (
            <div>
              <Button
                onClick={() => setBrandUpdateOpen(true)}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>

              <Button
                disabled={deactivateLoading || reactivateLoading}
                onClick={onBrandActiveClick}
                startIcon={active ? <CloseIcon /> : <ReplayIcon />}
              >
                {active ? "Deactivate" : "Reactivate"}
              </Button>
            </div>
          )}
        </div>

        <AppBar color="default" elevation={0} position="static">
          <Tabs
            onChange={(_, value: string) => {
              history.push(`${value}`);
            }}
            value={tabIndex}
          >
            <Tab label="Details" value={"details"} />
            <Tab label="Offers" value={"offers"} />
          </Tabs>
        </AppBar>

        <div className={classes.tabContent} hidden={tabIndex !== "details"}>
          <Typography>
            {`Tracking Link: ${
              trackingLink ? trackingLink + (trackingLinkAppend || "") : "N/A"
            }`}
          </Typography>
        </div>

        <div className={classes.tabContent} hidden={tabIndex !== "offers"}>
          {tabIndex === "offers" && (
            <OfferListContainer
              brand={{
                __typename: "Brand",
                advertiser: {
                  __typename: "Advertiser",
                  id: advertiser.id,
                  name: advertiser.name
                },
                id,
                name,
                trackingLink,
                trackingLinkAppend
              }}
            />
          )}
        </div>
      </Paper>

      {updateBrands && (
        <>
          <ConfirmationAlert
            content={activeConfirmationContent}
            onNegative={onConfirmationNegative}
            onPositive={onConfirmationPositive}
            open={activeConfirmationOpen}
            positiveAction={activeConfirmationPositiveAction}
            title={activeConfirmationTitle}
          />

          <BrandForm
            advertiser={advertiser}
            brandId={id}
            logoBanner={logoBanner}
            logoIcon={logoIcon}
            name={name}
            onClose={onBrandUpdateClose}
            open={brandUpdateOpen}
            trackingLink={trackingLink}
            trackingLinkAppend={trackingLinkAppend}
            type="update"
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: {
    marginRight: spacing()
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(2)
  },
  offersHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  tabContent: {
    padding: spacing(2)
  }
}));

export default BrandShow;
