import React, { useState } from "react";

import {
  Checkbox,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ReplayIcon from "@material-ui/icons/Replay";
import Undo from "@material-ui/icons/Undo";

import MonthlyPaymentDialog from "../MonthlyPaymentDialog";

import { MonthlyPaymentStatus } from "../../generated/globalTypes";
import { monthlyPaymentSummaries_monthlyPaymentSummaries_edges_node } from "../../generated/monthlyPaymentSummaries";

interface IMonthlyPaymentsListItem {
  monthClosed: boolean;
  monthLocked: boolean;
  monthlyPayment: monthlyPaymentSummaries_monthlyPaymentSummaries_edges_node;
  onClickCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectPayment: (id: string) => void;
  onSelectPaymentAction: (action: MonthlyPaymentStatus) => void;
  selected: boolean;
  onPaymentUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MonthlyPaymentsListItem = ({
  monthClosed,
  monthLocked,
  monthlyPayment,
  onClickCheckBox,
  onSelectPayment,
  onSelectPaymentAction,
  selected,
  onPaymentUpdate
}: IMonthlyPaymentsListItem) => {
  const classes = useStyles();

  const [mouseOver, setMouseOver] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentType, setPaymentType] = useState(0);

  const handlePaymentStatusUpdate = (status: MonthlyPaymentStatus) => {
    onSelectPayment(monthlyPayment.partnerId);
    onSelectPaymentAction(status);
  };

  const renderStatusButtons = (monthlyStatus: MonthlyPaymentStatus) => {
    if (monthlyStatus === MonthlyPaymentStatus.PENDING) {
      return (
        <>
          <Tooltip arrow title="Mark as PAID">
            <IconButton
              className={`${classes.button} ${classes.paidBorder}`}
              color="primary"
              onClick={() =>
                handlePaymentStatusUpdate(MonthlyPaymentStatus.PAID)
              }
              size="small"
              value={MonthlyPaymentStatus.PAID}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="ROLLOVER Payment to Next Month">
            <IconButton
              className={`${classes.button} ${classes.rolloverBorder}`}
              color="secondary"
              onClick={() =>
                handlePaymentStatusUpdate(MonthlyPaymentStatus.ROLLOVER)
              }
              size="small"
              value={MonthlyPaymentStatus.ROLLOVER}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          <Tooltip arrow title="Mark as PENDING">
            <IconButton
              className={`${classes.button} ${classes.paidBorder}`}
              color="primary"
              onClick={() =>
                handlePaymentStatusUpdate(MonthlyPaymentStatus.PENDING)
              }
              size="small"
              value={MonthlyPaymentStatus.PENDING}
            >
              <Undo />
            </IconButton>
          </Tooltip>
        </>
      );
    }
  };

  // const handleDetailsPress = (type: number) => {
  //   setDialogOpen(true);
  //   setPaymentType(type);
  // };

  return (
    <>
      <TableRow
        hover
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <TableCell>
          {monthLocked && (
            <Checkbox
              checked={selected}
              onChange={onClickCheckBox}
              value={monthlyPayment.partnerId}
            />
          )}
        </TableCell>
        <TableCell>
          {monthLocked && mouseOver
            ? renderStatusButtons(monthlyPayment.status)
            : monthlyPayment.status}
        </TableCell>
        <TableCell>{`${monthlyPayment.partnerName} (${monthlyPayment.partnerDisplayId})`}</TableCell>
        <TableCell>
          {monthlyPayment.updatedBy
            ? `${monthlyPayment.updatedBy.name} (${monthlyPayment.updatedBy.displayId})`
            : "-"}
        </TableCell>
        <TableCell>
          {monthlyPayment.updatedAt
            ? `${monthlyPayment.updatedAt.split(".")[0].replace("T", " ")}`
            : "-"}
        </TableCell>
        <TableCell>
          {monthlyPayment.paymentMethod
            ? `${monthlyPayment.paymentMethod}`
            : "-"}
        </TableCell>
        <TableCell>
          {monthlyPayment.cpaTotal.toFixed(2)} {monthlyPayment.currency}
        </TableCell>
        <TableCell>
          {monthlyPayment.revshareTotal.toFixed(2)} {monthlyPayment.currency}
        </TableCell>
        <TableCell>
          {monthlyPayment.adjustmentsTotal.toFixed(2)} {monthlyPayment.currency}
        </TableCell>
        <TableCell>
          {monthlyPayment.referralCost.toFixed(2)} {monthlyPayment.currency}
        </TableCell>
        <TableCell>
          {monthlyPayment.rolloverTotal.toFixed(2)} {monthlyPayment.currency}
        </TableCell>
        <TableCell>
          {monthlyPayment.total.toFixed(2)} {monthlyPayment.currency}
        </TableCell>
        <TableCell>
          {monthlyPayment.partnerTotal.toFixed(2)}{" "}
          {monthlyPayment.partnerCurrency || monthlyPayment.currency}
        </TableCell>
        {(monthLocked || monthClosed) && (
          <>
            <TableCell>
              <TextField
                id="internal-comment"
                disabled={monthlyPayment.status !== "PENDING" || monthClosed}
                onChange={onPaymentUpdate}
              />
            </TableCell>
            <TableCell>
              <TextField
                id="external-comment"
                disabled={monthlyPayment.status !== "PENDING" || monthClosed}
                onChange={onPaymentUpdate}
              />
            </TableCell>
          </>
        )}
      </TableRow>

      <MonthlyPaymentDialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        month={monthlyPayment.month}
        partnerId={monthlyPayment.partnerId}
        partnerName={monthlyPayment.partnerName}
        partnerDisplayId={monthlyPayment.partnerDisplayId}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    "&:hover": {
      backgroundColor: "lightgray"
    },
    margin: spacing(0, 1)
  },
  paidBorder: {
    border: "1px solid rgba(92, 195, 132, 0.5)"
  },
  rolloverBorder: {
    border: "1px solid #f50057"
  }
}));

export default MonthlyPaymentsListItem;
