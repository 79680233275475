import React from "react";

import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";

import { CommissionType, Currency } from "../../generated/globalTypes";
import {
  Offer_offer_offer_defaultAgreement_networkTiers,
  Offer_offer_offer_defaultAgreement_partnerTiers
} from "../../generated/Offer";
import getCurrencySymbol from "../../utils/getCurrencySymbol";

export type DefaultTiers =
  | Offer_offer_offer_defaultAgreement_partnerTiers[]
  | Offer_offer_offer_defaultAgreement_networkTiers[];
interface ITiers {
  commissionType: CommissionType;
  currency: Currency;
  tiers: DefaultTiers;
}

const Tiers = ({ commissionType, currency, tiers }: ITiers) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        className={classes.icon}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ListIcon />
      </IconButton>

      <Popover
        id="tiers-mouse-over-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {tiers.map((tier, index) => (
          <List dense key={index}>
            <ListItem>
              <ListItemText primary={`Tier ${index + 1}`} />
            </ListItem>
            <ListItem>
              <ListItemText secondary={`Threshold: ${tier.threshold}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={`Threshold Type: ${tier.thresholdType}`}
              />
            </ListItem>
            {commissionType !== CommissionType.REVSHARE && (
              <ListItem>
                <ListItemText
                  secondary={`CPA: ${getCurrencySymbol(currency)}${tier.cpa}`}
                />
              </ListItem>
            )}
            {commissionType !== CommissionType.CPA &&
              commissionType !== CommissionType.SPEND && (
                <ListItem>
                  <ListItemText
                    secondary={`revshare: ${tier.revsharePercentages.default}`}
                  />
                </ListItem>
              )}
          </List>
        ))}
      </Popover>
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    borderRadius: 0,
    margin: spacing(0, 1),
    padding: 0
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  popover: {
    pointerEvents: "none"
  }
}));

export default Tiers;
