import React from "react";

import {
  Button,
  ButtonGroup,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";

import { QualificationType } from "../../generated/globalTypes";

import { SubagreementLogs_subagreementLogs_edges_node as ILogNode } from "../../generated/SubagreementLogs";

import { IVerticalMap } from "../../utils/verticalsMap";
import RevsharePercentage from "../RevsharePercentage";

import { useAuth0 } from "../../utils/auth0Provider";

interface ISubLogListProps {
  offer?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  logs: ILogNode[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  verticals: IVerticalMap;
}

const SubagreementLogsList = ({
  offer,
  hasNextPage,
  hasPreviousPage,
  logs,
  onNextPage,
  onPreviousPage,
  verticals
}: ISubLogListProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const readNetworkDetails = hasPermission("read:agreements:network_details");

  const compareLogs = (logA: ILogNode, logB: ILogNode) => {
    const logAid: number = logA.createdAt;
    const logBid: number = logB.createdAt;

    let comparison = 0;
    if (logAid > logBid) {
      comparison = 1;
    } else if (logAid < logBid) {
      comparison = -1;
    }
    return comparison * -1;
  };

  const sortedLogs = logs.sort(compareLogs);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h4">Subagreement Logs</Typography>
        <div className={classes.source}>
          <Typography variant="h5">offer:{offer}</Typography>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Change Date</TableCell>
            {readNetworkDetails && (
              <>
                <TableCell />
                <TableCell>Network Type</TableCell>
                <TableCell>Network CPA Action</TableCell>
                <TableCell>Network CPA</TableCell>
                <TableCell>Network RS %</TableCell>
              </>
            )}
            <TableCell />
            <TableCell>Partner Type</TableCell>
            <TableCell>Partner CPA Action</TableCell>
            <TableCell>Partner CPA</TableCell>
            <TableCell>Partner RS %</TableCell>
            <TableCell>Partner Spend %</TableCell>
            <TableCell />
            <TableCell>Min Deposit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedLogs.map(log => {
            const createdDate = new Date(log.createdAt);
            const networkDetails = log.fromSubagreement.networkDetails;
            const affiliateDetails = log.fromSubagreement.affiliateDetails;
            const minDeposit = log.fromSubagreement.qualifications.find(
              qualification =>
                qualification.qualificationType ===
                QualificationType.MIN_DEPOSIT
            );
            return (
              <TableRow key={log.id}>
                <TableCell>{createdDate.toLocaleString()}</TableCell>
                <TableCell />
                {readNetworkDetails && (
                  <TableCell className={classes.capitalize}>
                    {networkDetails?.commissionType.toLowerCase()}
                  </TableCell>
                )}
                {networkDetails &&
                networkDetails.__typename !== "AgreementRevshare" &&
                networkDetails.__typename !== "AgreementSpend" ? (
                  <>
                    <TableCell className={classes.capitalize}>
                      {networkDetails.actionType.toLowerCase()}
                    </TableCell>
                    <TableCell>
                      {networkDetails.cpaAmount} {networkDetails.currency}
                    </TableCell>
                  </>
                ) : (
                  <></>
                )}

                {networkDetails &&
                networkDetails.__typename !== "AgreementCpa" &&
                networkDetails.__typename !== "AgreementSpend" ? (
                  <TableCell>
                    <RevsharePercentage
                      revsharePercentages={networkDetails.revsharePercentages}
                      verticals={verticals}
                    />
                  </TableCell>
                ) : (
                  <></>
                )}

                {readNetworkDetails && (
                  <>
                    <TableCell />
                    <TableCell />
                  </>
                )}

                <TableCell className={classes.capitalize}>
                  {affiliateDetails.commissionType.toLowerCase()}
                </TableCell>

                {affiliateDetails.__typename !== "AgreementRevshare" &&
                affiliateDetails.__typename !== "AgreementSpend" ? (
                  <>
                    <TableCell className={classes.capitalize}>
                      {affiliateDetails.actionType.toLowerCase()}
                    </TableCell>
                    <TableCell>
                      {affiliateDetails.cpaAmount} {affiliateDetails.currency}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell />
                    <TableCell />
                  </>
                )}

                {affiliateDetails.__typename !== "AgreementCpa" &&
                affiliateDetails.__typename !== "AgreementSpend" ? (
                  <TableCell>
                    <RevsharePercentage
                      revsharePercentages={affiliateDetails.revsharePercentages}
                      verticals={verticals}
                    />
                  </TableCell>
                ) : (
                  <TableCell />
                )}

                {affiliateDetails.__typename === "AgreementSpend" ? (
                  <TableCell>{affiliateDetails.spendPercentage}%</TableCell>
                ) : (
                  <TableCell />
                )}

                <TableCell />

                <TableCell>{minDeposit?.amount || "N/A"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell} colSpan={1000}>
              <Toolbar className={classes.toolbar}>
                <ButtonGroup>
                  <Button disabled={!hasPreviousPage} onClick={onPreviousPage}>
                    {"<"}
                  </Button>
                  <Button disabled={!hasNextPage} onClick={onNextPage}>
                    {">"}
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  capitalize: { textTransform: "capitalize" },
  container: { paddingTop: spacing() },
  header: {
    padding: spacing()
  },
  paginationTableCell: { padding: 0 },
  source: { padding: spacing(3) },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default SubagreementLogsList;
