import { gql } from "@apollo/client";

const UsersQuery = gql`
  query Users(
    $approved: Boolean
    $page: Int
    $search: String
    $sortAttribute: UserSortFields
    $sortOrder: SortOrder
  ) {
    users(
      approved: $approved
      page: $page
      search: $search
      sortAttribute: $sortAttribute
      sortOrder: $sortOrder
    ) {
      total
      users {
        active
        approved
        company
        createdAt
        displayId
        email
        id
        name
        phoneNumber
        referrer {
          id
          name
        }
        referrerEarningsPercentage
        referrerRevsharePercentage
        roles {
          id
          name
        }
      }
    }
  }
`;

export default UsersQuery;
