import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { AccountPools_accountPools_edges_node } from "../../generated/AccountPools";

import AccountPoolForm from "../AccountPoolForm";
import AccountPoolListItem from "../AccountPoolListItem/AccountPoolListItem";

interface IAccountPoolListProps {
  accountPools: AccountPools_accountPools_edges_node[];
  handleSearch: (value?: string) => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const AccountPoolList = ({
  accountPools,
  handleSearch,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage
}: IAccountPoolListProps) => {
  const classes = useStyles();

  const [accountPoolCreateOpen, setAccountPoolCreateOpen] = useState(false);
  const [poolSearch, setPoolSearch] = useState("");

  const handlePoolSearch = (value: string) => {
    setPoolSearch(value);
    handleSearch(value || undefined);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h5">Account Pools</Typography>

          <TextField
            id="pool-search"
            onChange={event => handlePoolSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            value={poolSearch}
          />

          <Button
            color="primary"
            onClick={() => setAccountPoolCreateOpen(true)}
            variant="contained"
          >
            <AddIcon className={classes.buttonIcon} />
            Account Pool
          </Button>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {accountPools.map(accountPool => (
              <AccountPoolListItem {...accountPool} key={accountPool.id} />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell className={classes.paginationTableCell} colSpan={1000}>
                <Toolbar className={classes.toolbar}>
                  <ButtonGroup>
                    <Button
                      disabled={!hasPreviousPage}
                      onClick={onPreviousPage}
                    >
                      {"<"}
                    </Button>
                    <Button disabled={!hasNextPage} onClick={onNextPage}>
                      {">"}
                    </Button>
                  </ButtonGroup>
                </Toolbar>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>

      <AccountPoolForm
        onClose={() => setAccountPoolCreateOpen(false)}
        open={accountPoolCreateOpen}
        type="create"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: {
    marginRight: spacing()
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: {
    padding: 0
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default AccountPoolList;
