import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";

import { CreateUserVariables } from "../../generated/CreateUser";
import { UserCurrency } from "../../generated/globalTypes";
import { Roles_roles_roles as IRolesQuery } from "../../generated/Roles";
import CreateUserMutation from "../../mutations/CreateUserMutation";
import ConfirmationAlert from "../ConfirmationAlert";
import InputTextMask, { phoneNumberMask } from "../InputTextMask";

interface IAddUserFormProps {
  onClose: () => void;
  onSetNewUser: any;
  open: boolean;
  roleOptions: IRolesQuery[];
}

const UserForm = ({
  onClose,
  onSetNewUser,
  open,
  roleOptions
}: IAddUserFormProps) => {
  const emptyStringValue = undefined;

  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [nameState, setName] = useState<string | undefined>();
  const [emailState, setEmail] = useState<string | undefined>();
  const [companyState, setCompany] = useState<string | undefined>();
  const [phoneNumberState, setPhoneNumber] = useState<string | undefined>();
  const [currencyState, setCurrency] = useState<string | undefined>();
  const [roleIdState, setRoleId] = useState<string | undefined>();

  const [createUser, { loading }] = useMutation(CreateUserMutation, {
    onCompleted: data => {
      onSetNewUser(data.createUser.user);
    }
  });

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setCompany(emptyStringValue);
    setCurrency(emptyStringValue);
    setEmail(emptyStringValue);
    setName(emptyStringValue);
    setPhoneNumber(emptyStringValue);
    setRoleId(emptyStringValue);
    setDirty(false);
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const variables: CreateUserVariables = {
      company: companyState,
      currency: currencyState as UserCurrency,
      email: emailState!,
      name: nameState!,
      phoneNumber: phoneNumberState,
      roleId: roleIdState!
    };

    createUser({
      refetchQueries: ["Users"],
      variables
    }).then(() => onCloseReset());
  };

  const setInputState = (setFunction: (value?: string | undefined) => void) => (
    event: ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    setDirty(true);
    setFunction((event.target.value as string) || emptyStringValue);
  };

  const dialogTitle = "Create User";

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              className={classes.formControl}
              id="name"
              fullWidth
              label="Name"
              onChange={setInputState(setName)}
              required
              value={nameState || ""}
            />

            <TextField
              className={classes.formControl}
              id="email"
              fullWidth
              label="Email"
              onChange={setInputState(setEmail)}
              required
              value={emailState || ""}
            />

            <TextField
              className={classes.formControl}
              id="company"
              fullWidth
              label="Company"
              onChange={setInputState(setCompany)}
              value={companyState || ""}
            />

            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="phone-number">Phone Number</InputLabel>
              <Input
                id="phone-number"
                inputComponent={InputTextMask as any}
                inputProps={{ mask: phoneNumberMask }}
                onChange={setInputState(setPhoneNumber)}
                value={phoneNumberState || ""}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="userCurrency">Currency</InputLabel>
              <Select
                labelId="userCurrency"
                id="userCurrencySelect"
                onChange={setInputState(setCurrency)}
                value={currencyState || ""}
              >
                <MenuItem value="USD">US Dollars ($)</MenuItem>
                <MenuItem value="EUR">Euros (€)</MenuItem>
                <MenuItem value="GBP">British Pounds (£)</MenuItem>
                <MenuItem value="BTC">Bitcoin (₿)</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              className={`${classes.formControl} ${classes.marginLeft}`}
            >
              <InputLabel id="userRole">Role</InputLabel>
              <Select
                labelId="userRole"
                id="userRoleSelect"
                onChange={setInputState(setRoleId)}
                value={roleIdState || ""}
                required
              >
                {roleOptions.map(role => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    minWidth: 150,
    paddingBottom: spacing(2)
  },
  marginLeft: {
    marginLeft: spacing(4)
  }
}));

export default UserForm;
