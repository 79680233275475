import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";

import { IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { AccountPool_accountPool_accountPool_variables } from "../../generated/AccountPool";
import AccountPoolForm from "../AccountPoolForm";

interface IAccountPoolListItemProps {
  description: string | null;
  id: number;
  name: string;
  variables: AccountPool_accountPool_accountPool_variables[];
}

const AccountPoolListItem = ({
  description,
  id,
  name,
  variables
}: IAccountPoolListItemProps) => {
  const history = useHistory();

  const classes = useStyles();

  const [accountPoolUpdateOpen, setAccountPoolUpdateOpen] = useState(false);

  const onEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAccountPoolUpdateOpen(true);
  };

  return (
    <>
      <TableRow
        className={classes.tableRow}
        hover
        key={id}
        onClick={() => history.push(`/account-pools/${id}`)}
      >
        <TableCell>{id}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <AccountPoolForm
        accountPoolId={id}
        description={description}
        name={name}
        onClose={() => setAccountPoolUpdateOpen(false)}
        open={accountPoolUpdateOpen}
        type="update"
        variables={variables}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  tableRow: {
    cursor: "pointer"
  }
}));

export default AccountPoolListItem;
