import React from "react";

import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";

import {
  CommissionType,
  Currency,
  ThresholdType
} from "../../generated/globalTypes";
import {
  Offer_offer_offer_defaultAgreement_networkTiers,
  Offer_offer_offer_defaultAgreement_partnerTiers
} from "../../generated/Offer";
import getCurrencySymbol from "../../utils/getCurrencySymbol";

interface IAgreementTiersCard {
  commissionType: CommissionType;
  currency: Currency;
  networkTiers?: Offer_offer_offer_defaultAgreement_networkTiers[];
  partnerTiers?: Offer_offer_offer_defaultAgreement_partnerTiers[];
}

const AgreementTiersCard = ({
  commissionType,
  currency,
  networkTiers,
  partnerTiers
}: IAgreementTiersCard) => {
  const classes = useStyles();

  const tiers = networkTiers || partnerTiers;

  return (
    <Paper className={classes.paper}>
      <Typography align="center" variant="h6">
        {`${networkTiers ? "Network" : "Partner"} Tiers`}
      </Typography>
      <Table>
        {tiers?.length ? (
          tiers.map((tier, index) => (
            <TableBody className={classes.tier} key={index}>
              <TableRow>
                <TableCell className={classes.tierRow}>
                  <strong>{`Tier ${index + 1}`}</strong>
                </TableCell>
                <TableCell className={classes.tierRow}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tierRow}>
                  Threshold Type
                </TableCell>
                <TableCell className={classes.tierRow}>
                  {tier.thresholdType.replace(/_/g, " ")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tierRow}>Threshold</TableCell>
                <TableCell className={classes.tierRow}>
                  {`${
                    tier.thresholdType === ThresholdType.NET_REVENUE
                      ? getCurrencySymbol(currency)
                      : ""
                  }${tier.threshold}`}
                </TableCell>
              </TableRow>
              {commissionType !== CommissionType.REVSHARE && (
                <TableRow>
                  <TableCell className={classes.tierRow}>CPA</TableCell>
                  <TableCell className={classes.tierRow}>{`${getCurrencySymbol(
                    currency
                  )}${tier.cpa}`}</TableCell>
                </TableRow>
              )}
              {commissionType !== CommissionType.CPA && (
                <TableRow>
                  <TableCell className={classes.tierRow}>RS %</TableCell>
                  <TableCell className={classes.tierRow}>
                    {tier.revsharePercentages.default}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          ))
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align={"center"}>N/A</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    margin: spacing(),
    padding: spacing(2)
  },
  tier: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    paddingTop: spacing()
  },
  tierHeader: {
    paddingLeft: spacing()
  },
  tierRow: {
    border: "none",
    padding: spacing(1, 1)
  }
}));

export default AgreementTiersCard;
