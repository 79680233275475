import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useAuth0 } from "../../utils/auth0Provider";

import { Creatives_creatives_edges_node } from "../../generated/Creatives";

import { Offer_offer_offer_brand } from "../../generated/Offer";
import { DestinationLinkOffer } from "../../utils/destinationLinkPreview/types";
import CreativeForm from "../CreativeForm";
import CreativeListItem from "../CreativeListItem";

interface ICreativeListProps {
  creatives: Creatives_creatives_edges_node[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  brand: Offer_offer_offer_brand;
  offer: DestinationLinkOffer;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const CreativeList = ({
  creatives,
  hasNextPage,
  hasPreviousPage,
  offer,
  brand,
  onNextPage,
  onPreviousPage
}: ICreativeListProps) => {
  const classes = useStyles();
  const offerId = offer.id;
  const { hasPermission } = useAuth0();
  const createCreatives = hasPermission("manage:creatives");
  const updateCreatives = hasPermission("manage:creatives");

  const [creativeCreateOpen, setCreativeCreateOpen] = useState(false);

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5">Creatives</Typography>

        {createCreatives && (
          <Button
            color="primary"
            onClick={() => setCreativeCreateOpen(true)}
            variant="contained"
          >
            <AddIcon className={classes.buttonIcon} />
            Creative
          </Button>
        )}
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellId}>ID</TableCell>
            <TableCell className={classes.cellName}>Name</TableCell>
            <TableCell className={classes.cellLanguage}>Language</TableCell>
            <TableCell className={classes.cellImage}>Image</TableCell>
            <TableCell className={classes.cellDimension}>Width</TableCell>
            <TableCell>Height</TableCell>
            <TableCell className={classes.cellAction}>Active</TableCell>
            {updateCreatives && (
              <TableCell className={classes.cellAction}>Edit</TableCell>
            )}
            <TableCell className={classes.cellAction}>Link</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {creatives.map(creative => {
            return (
              <CreativeListItem
                {...creative}
                brand={brand}
                offer={offer}
                key={creative.id}
              />
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell} colSpan={1000}>
              <Toolbar className={classes.toolbar}>
                <ButtonGroup>
                  <Button disabled={!hasPreviousPage} onClick={onPreviousPage}>
                    {"<"}
                  </Button>
                  <Button disabled={!hasNextPage} onClick={onNextPage}>
                    {">"}
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      {createCreatives && (
        <CreativeForm
          offerId={offerId}
          onClose={() => setCreativeCreateOpen(false)}
          open={creativeCreateOpen}
          brand={brand}
          offer={offer}
          type="create"
        />
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: { marginRight: spacing() },
  cellAction: { width: "5%" },
  cellDimension: { width: "5%" },
  cellId: {
    textAlign: "right",
    width: "5%"
  },
  cellImage: { width: "10%" },
  cellLanguage: { width: "10%" },
  cellName: { width: "15%" },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: { padding: 0 },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default CreativeList;
