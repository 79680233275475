import clsx from "clsx";
import React from "react";
import { useDropzone } from "react-dropzone";

import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

interface IDropzoneProps {
  complete: boolean;
  disabled: boolean;
  fileName?: string;
  onDropCsvFile: (csvFile: File | undefined) => void;
}

const CsvDropzone = ({
  complete,
  disabled,
  fileName,
  onDropCsvFile
}: IDropzoneProps) => {
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject
  } = useDropzone({
    accept: ".csv",
    disabled,
    onDrop: files => {
      if (files.length) {
        const file = files[0];
        onDropCsvFile(file);
      }
    }
  });

  return (
    <>
      <div
        className={clsx(
          classes.container,
          isDragActive ? classes.dragActive : undefined
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragAccept && <p>Drop to upload...</p>}
        {isDragReject && <p>Files must be of type .csv</p>}
        {disabled && <p>Uploading files...</p>}
        {!isDragActive && !disabled && !complete && (
          <p>Drag file here, or click to select file</p>
        )}
        {complete && (
          <>
            <p className={classes.message}>Upload complete.</p>
            <p className={classes.message}>
              Drag file here, or click to select new file
            </p>
          </>
        )}
      </div>

      <List className={classes.list}>
        {fileName && (
          <ListItem>
            <ListItemText primary={fileName} />
            <ListItemSecondaryAction>
              {complete ? (
                <CheckIcon color="primary" />
              ) : (
                <IconButton
                  edge="end"
                  onClick={() => onDropCsvFile(undefined)}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </>
  );
};

const useStyles = makeStyles(({ palette, shape }) => ({
  container: {
    alignItems: "center",
    background: palette.grey[200],
    borderRadius: shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    height: 150,
    justifyContent: "center",
    width: 400
  },
  dragActive: {
    borderColor: palette.primary.light,
    borderStyle: "solid",
    borderWidth: 1
  },
  list: {
    minWidth: 400,
    width: "fit-content"
  },
  message: {
    margin: 0
  }
}));

export default CsvDropzone;
