import { ApolloQueryResult, useMutation } from "@apollo/client";
import { format } from "date-fns";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import {
  CreateDefaultAgreement,
  CreateDefaultAgreementVariables,
} from "../../generated/CreateDefaultAgreement";
import {
  ActionType,
  AgreementTierType,
  CommissionType,
  CreateQualificationInput,
  CreateTierInput,
  Currency,
  PayType,
  QualificationType,
  QualificationWindowType,
  ThresholdType,
  TierWindowType,
  VerticalType,
} from "../../generated/globalTypes";
import { Offer as IOfferQuery } from "../../generated/Offer";
import CreateDefaultAgreementMutation from "../../mutations/CreateDefaultAgreementMutation";

import { muiDatetimeFormatter } from "../../utils/muiDatetimeFormatter";
import { verticalsMap } from "../../utils/verticalsMap";
import ConfirmationAlert from "../ConfirmationAlert";

interface IAgreementFormProps {
  affiliateDetails?: IAgreementDetailsState;
  networkDetails?: IAgreementDetailsState | null;
  offerId: number;
  offerRefetch: () => Promise<ApolloQueryResult<IOfferQuery>>;
  onClose: () => void;
  open: boolean;
  networkTiers?: ITierState[];
  partnerTiers?: ITierState[];
  qualifications?: IQualificationState[];
  type: "create" | "update";
  ringfenceRevshare?: boolean;
  startAt?: string | null;
  endAt?: string | null;
}

interface IAgreementDetailsState {
  actionType?: ActionType;
  commissionType?: CommissionType;
  cpaAmount?: number;
  currency?: Currency;
  revsharePercentages?: { [key in VerticalType]?: number };
  spendAccessToken?: string;
  spendPercentage?: number;
  tierType?: AgreementTierType | null;
}

export interface IQualificationState {
  amount?: number;
  qualificationType?: QualificationType;
  windowType?: QualificationWindowType | null;
}

export interface ITierState {
  payType?: PayType;
  threshold?: number;
  thresholdType?: ThresholdType;
  cpa?: number;
  revsharePercentages?: { [key in VerticalType]?: number };
  windowType?: TierWindowType | null;
}

const AgreementForm = ({
  affiliateDetails,
  networkDetails,
  offerId,
  offerRefetch,
  onClose,
  open,
  qualifications,
  networkTiers,
  partnerTiers,
  ringfenceRevshare,
  startAt,
  endAt,
  type,
}: IAgreementFormProps) => {
  const emptyStringValue = undefined;

  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const createNetworkDetails =
    hasPermission("manage:agreements:network_details") && type === "create";
  const updateNetworkDetails =
    hasPermission("manage:agreements:network_details") && type === "update";
  const enableStartEndDates = hasPermission("manage:agreements:dates");

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [affiliateDetailsState, setAffiliateDetails] = useState<
    IAgreementDetailsState
  >(affiliateDetails || {});
  const [networkDetailsState, setNetworkDetails] = useState<
    IAgreementDetailsState
  >(networkDetails || {});
  const [qualificationsState, setQualifications] = useState<
    IQualificationState[]
  >(qualifications || []);
  const [networkTiersState, setNetworkTiers] = useState(networkTiers || []);
  const [partnerTiersState, setPartnerTiers] = useState(partnerTiers || []);

  const [startState, setStartState] = useState(muiDatetimeFormatter(startAt));
  const [endState, setEndState] = useState(muiDatetimeFormatter(endAt));

  const [
    partnerMultiProductRevshareState,
    setPartnerMultiProductRevshare,
  ] = useState(
    affiliateDetails?.revsharePercentages
      ? Object.keys(affiliateDetails?.revsharePercentages).length > 1
      : false
  );
  const [
    networkMultiProductRevshareState,
    setNetworkMultiProductRevshare,
  ] = useState(
    networkDetails?.revsharePercentages
      ? Object.keys(networkDetails?.revsharePercentages).length > 1
      : false
  );

  const [ringfenceRevshareState, setRingfenceRevshareState] = useState(
    ringfenceRevshare
  );

  const [createDefaultAgreement, { loading }] = useMutation<
    CreateDefaultAgreement,
    CreateDefaultAgreementVariables
  >(CreateDefaultAgreementMutation, { errorPolicy: "all" });

  useEffect(() => {
    setAffiliateDetails(affiliateDetails || {});
    setNetworkDetails(networkDetails || {});
    setPartnerMultiProductRevshare(
      affiliateDetails?.revsharePercentages
        ? Object.keys(affiliateDetails?.revsharePercentages).length > 1
        : false
    );
    setNetworkMultiProductRevshare(
      networkDetails?.revsharePercentages
        ? Object.keys(networkDetails?.revsharePercentages).length > 1
        : false
    );
    setQualifications(qualifications || []);
    setNetworkTiers(networkTiers || []);
    setPartnerTiers(partnerTiers || []);
    setRingfenceRevshareState(ringfenceRevshare || false);
    setStartState(muiDatetimeFormatter(startAt));
    setEndState(muiDatetimeFormatter(endAt));
  }, [
    affiliateDetails,
    networkDetails,
    qualifications,
    networkTiers,
    partnerTiers,
    ringfenceRevshare,
    startAt,
    endAt,
  ]);

  const handleQualificationSwitchChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    event.target.checked ? handleAddQualification() : setQualifications([]);
  };

  const handleQualificationChange = (
    event: ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    setDirty(true);
    const newQualifications = qualificationsState.map((qualification) => ({
      ...qualification,
    }));
    const nameSplit = event.target.name!.split("-");
    Object.assign(newQualifications[parseInt(nameSplit[1], 10)], {
      [nameSplit[0]]:
        nameSplit[0] === "amount"
          ? parseFloat(event.target.value as string)
          : event.target.value,
    });
    setQualifications(newQualifications);
  };

  const handleQualificationBlur = (
    event: ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    const newQualifications = qualificationsState.map((qualification) => ({
      ...qualification,
    }));
    const nameSplit = event.target.name!.split("-");
    Object.assign(newQualifications[parseInt(nameSplit[1], 10)], {
      [nameSplit[0]]: parseFloat(
        Number(event.target.value as string).toFixed(2)
      ),
    });
    event.target.value = Number(event.target.value).toFixed(2);
    setQualifications(newQualifications);
  };

  const handleAddQualification = () => {
    if (qualificationsState.length >= Object.keys(QualificationType).length) {
      return;
    }
    setQualifications(qualificationsState.concat([{}]));
  };

  const handleRemoveQualification = () => {
    setDirty(true);
    setQualifications(
      qualificationsState.slice(0, qualificationsState.length - 1)
    );
  };

  const handleTierSwitchChange = (
    event: ChangeEvent<HTMLInputElement>,
    payType: PayType
  ) => {
    setDirty(true);
    if (event.target.checked) {
      handleAddTier(payType)
      payType === PayType.NETWORK
        ? setNetworkDetails({ ...networkDetailsState, tierType: AgreementTierType.SUBAGREEMENT })
        : setAffiliateDetails({ ...affiliateDetailsState, tierType: AgreementTierType.SUBAGREEMENT });
    } else if (payType === PayType.NETWORK) {
      setNetworkTiers([])
      setNetworkDetails({ ...networkDetailsState, tierType: undefined })
    } else {
      setPartnerTiers([]);
      setAffiliateDetails({ ...affiliateDetailsState, tierType: undefined })
    }
  };

  const handleAgreementTierChange = (
    event: ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >, payType: PayType
  ) => {
    const newTier: ITierState = { payType };
    if (payType === PayType.NETWORK) {
      setNetworkTiers([newTier])
      setNetworkDetails({
        ...networkDetailsState,
        tierType: event.target.value as AgreementTierType,
      })
    } else {
      setPartnerTiers([newTier]);
      setAffiliateDetails({
        ...affiliateDetailsState,
        tierType: event.target.value as AgreementTierType,
      })
    }
  }

  const handleAddTier = (payType: PayType) => {
    const tiersState =
      payType === PayType.NETWORK ? networkTiersState : partnerTiersState;
    const newTier: ITierState = {
      payType,
      thresholdType:
        tiersState.length >= 1 ? tiersState[0].thresholdType : undefined,
      windowType: tiersState.length >= 1 ? tiersState[0].windowType : undefined,
    };

    payType === PayType.NETWORK
      ? setNetworkTiers(networkTiersState.concat([newTier]))
      : setPartnerTiers(partnerTiersState.concat([newTier]));
  };

  const handleRemoveTier = (payType: PayType) => {
    setDirty(true);

    const tiersSetFunction =
      payType === PayType.NETWORK ? setNetworkTiers : setPartnerTiers;
    const tiersState =
      payType === PayType.NETWORK ? networkTiersState : partnerTiersState;

    tiersSetFunction(tiersState.slice(0, tiersState.length - 1));
  };

  const handleTierChange = (
    event: ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
    payType: PayType
  ) => {
    setDirty(true);

    const newTiers = (payType === PayType.NETWORK
      ? networkTiersState
      : partnerTiersState
    ).map((tier) => ({
      ...tier,
    }));

    const nameSplit = event.target.name!.split("-");
    Object.assign(
      newTiers[parseInt(nameSplit[1], 10)],
      nameSplit[0] === "revsharePercentages"
        ? {
            revsharePercentages: {
              [VerticalType.default]: parseFloat(event.target.value as string),
            },
          }
        : {
            [nameSplit[0]]:
              nameSplit[0] === "threshold" || nameSplit[0] === "cpa"
                ? parseFloat(event.target.value as string)
                : event.target.value,
          }
    );

    if (["thresholdType", "windowType"].includes(nameSplit[0])) {
      newTiers.map((tier) => {
        Object.assign(tier, { [nameSplit[0]]: event.target.value })
      })

      if (nameSplit[0] === "thresholdType") {
        if (![ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(event.target.value as ThresholdType)) {
          newTiers.map((tier) => {
            Object.assign(tier, { windowType: undefined })
          })
        }
      }
    }

    payType === PayType.NETWORK
      ? setNetworkTiers(newTiers)
      : setPartnerTiers(newTiers);
  };

  const handleRingfenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setRingfenceRevshareState(event.target.checked);
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    setConfirmOpen(false);
    setAffiliateDetails(affiliateDetails || {});
    setNetworkDetails(networkDetails || {});
    setPartnerMultiProductRevshare(
      affiliateDetails?.revsharePercentages
        ? Object.keys(affiliateDetails?.revsharePercentages).length > 1
        : false
    );
    setNetworkMultiProductRevshare(
      networkDetails?.revsharePercentages
        ? Object.keys(networkDetails?.revsharePercentages).length > 1
        : false
    );
    setQualifications(qualifications || []);
    setNetworkTiers(networkTiers || []);
    setPartnerTiers(partnerTiers || []);
    setRingfenceRevshareState(ringfenceRevshare || false);
    setStartState(muiDatetimeFormatter(startAt));
    setEndState(muiDatetimeFormatter(endAt));
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const networkTiersVariable: CreateTierInput[] = networkTiersState.map(
      (tier) => ({
        cpa: tier.cpa,
        payType: PayType.NETWORK,
        revsharePercentages: tier.revsharePercentages,
        threshold: tier.threshold!,
        thresholdType: tier.thresholdType!,
        windowType: [ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(tier.thresholdType!) ? tier.windowType : undefined,
      })
    );

    const partnerTiersVariable: CreateTierInput[] = partnerTiersState.map(
      (tier) => ({
        cpa: tier.cpa,
        payType: PayType.PARTNER,
        revsharePercentages: tier.revsharePercentages,
        threshold: tier.threshold!,
        thresholdType: tier.thresholdType!,
        windowType: [ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(tier.thresholdType!) ? tier.windowType : undefined,
      })
    );

    const variables: CreateDefaultAgreementVariables = {
      affiliateDetails: {
        actionType: affiliateDetailsState.actionType,
        commissionType: affiliateDetailsState.commissionType!,
        cpaAmount:
          affiliateDetailsState.commissionType === CommissionType.REVSHARE
            ? undefined
            : affiliateDetailsState.cpaAmount,
        currency: affiliateDetailsState.currency!,
        revsharePercentages:
          affiliateDetailsState.commissionType === CommissionType.CPA
            ? undefined
            : affiliateDetailsState.revsharePercentages,
        spendAccessToken: affiliateDetailsState.spendAccessToken,
        spendPercentage: affiliateDetailsState.spendPercentage,
        tierType: partnerTiersVariable.length === 0 ? undefined : affiliateDetailsState.tierType,
      },
      endAt: endState ? new Date(endState).toISOString() : endState,
      networkTiers: networkTiersVariable,
      offerId,
      partnerTiers: partnerTiersVariable,
      qualifications: qualificationsState.map((q) => ({
        amount: q.amount,
        qualificationType: q.qualificationType,
        windowType: q.qualificationType === QualificationType.DEPOSIT_WINDOW ? q.windowType : undefined,
      })) as CreateQualificationInput[],
      ringfenceRevshare: ringfenceRevshareState!,
      startAt: startState ? new Date(startState).toISOString() : startState,
    };

    if (createNetworkDetails || updateNetworkDetails) {
      Object.assign(variables, {
        networkDetails: {
          actionType: networkDetailsState.actionType,
          commissionType: networkDetailsState.commissionType!,
          cpaAmount:
            networkDetailsState.commissionType === CommissionType.REVSHARE
              ? undefined
              : networkDetailsState.cpaAmount,
          currency: networkDetailsState.currency!,
          revsharePercentages:
            networkDetailsState.commissionType === CommissionType.CPA
              ? undefined
              : networkDetailsState.revsharePercentages,
          tierType: networkTiersVariable.length === 0 ? undefined : networkDetailsState.tierType,
        },
      });
    }

    createDefaultAgreement({ variables }).then(async () => {
      await offerRefetch();
      setDirty(false);
      onClose();
    });
  };

  const dialogTitle =
    type === "create" ? "Create Default Agreement" : "Update Default Agreement";
  const dialogText =
    type === "create"
      ? "Add the default agreement for an offer."
      : "Update the existing default agreement for an offer.";

  const renderAgreementDetails = (detailsType: "affiliate" | "network") => {
    const state =
      detailsType === "affiliate" ? affiliateDetailsState : networkDetailsState;
    const setFunction =
      detailsType === "affiliate" ? setAffiliateDetails : setNetworkDetails;

    const checked =
      detailsType === "affiliate"
        ? partnerMultiProductRevshareState
        : networkMultiProductRevshareState;
    const checkedSetFunction =
      detailsType === "affiliate"
        ? setPartnerMultiProductRevshare
        : setNetworkMultiProductRevshare;

    const commissionTypeOptions = Object.keys(CommissionType).filter(
      (commissionType) => commissionType !== "SPEND"
    );

    const setInputState = (agreementDetails: IAgreementDetailsState) => {
      setDirty(true);
      setFunction({ ...state, ...agreementDetails });
      if (agreementDetails.commissionType === CommissionType.CLAWBACK) {
        if (detailsType === "affiliate") setPartnerTiers([]);
        if (detailsType === "network") setNetworkTiers([]);
      }
    };

    const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        setInputState(
          state.revsharePercentages?.default ||
            state.revsharePercentages?.default === 0
            ? {
                revsharePercentages: {
                  [VerticalType.default]: state.revsharePercentages.default,
                },
              }
            : {}
        );
      }
      checkedSetFunction(event.target.checked);
    };

    return (
      <>
        <FormControl className={classes.formControl} fullWidth required>
          <InputLabel htmlFor={`${detailsType}-commission-type`}>
            Commission Type
          </InputLabel>
          <Select
            inputProps={{ id: `${detailsType}-commission-type` }}
            native
            onChange={(event) =>
              setInputState({
                commissionType: event.target.value as CommissionType,
              })
            }
            required
            value={state.commissionType}
          >
            <option value="" />
            {commissionTypeOptions.map((commissionTypeOption) => (
              <option key={commissionTypeOption} value={commissionTypeOption}>
                {commissionTypeOption}
              </option>
            ))}
          </Select>
        </FormControl>

        {state.commissionType &&
          state.commissionType !== CommissionType.REVSHARE &&
          state.commissionType !== CommissionType.SPEND && (
            <>
              <FormControl className={classes.formControl} fullWidth required>
                <InputLabel htmlFor={`${detailsType}-action-type`}>
                  Action Type
                </InputLabel>
                <Select
                  inputProps={{ id: `${detailsType}-action-type` }}
                  native
                  onChange={(event) =>
                    setInputState({
                      actionType: event.target.value as ActionType,
                    })
                  }
                  required
                  value={state.actionType}
                >
                  <option value="" />
                  {Object.keys(ActionType).map((actionType) => (
                    <option key={actionType} value={actionType}>
                      {actionType}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <TextField
                className={classes.formControl}
                id={`${detailsType}-cpa-amount`}
                inputProps={{ min: 0, step: 0.01 }}
                fullWidth
                label="CPA Amount"
                onChange={(event) =>
                  setInputState({
                    cpaAmount: parseFloat(event.target.value),
                  })
                }
                required
                type="number"
                value={
                  state.cpaAmount === 0 || state.cpaAmount
                    ? state.cpaAmount
                    : ""
                }
              />
            </>
          )}

        {state.commissionType &&
          state.commissionType !== CommissionType.CPA &&
          state.commissionType !== CommissionType.SPEND && (
            <>
              <div className={classes.formGroup}>
                <TextField
                  className={`${classes.formControl} ${classes.formRevshare}`}
                  id={`${detailsType}-default-revshare-percentage`}
                  inputProps={{ max: 100, min: 0, step: 0.01 }}
                  label="Default Revshare Percentage"
                  onChange={(event) =>
                    setInputState({
                      revsharePercentages:
                        typeof parseFloat(event.target.value) === "number"
                          ? {
                              ...state.revsharePercentages,
                              [VerticalType.default]: parseFloat(
                                event.target.value
                              ),
                            }
                          : emptyStringValue,
                    })
                  }
                  required
                  type="number"
                  value={
                    typeof state.revsharePercentages?.default === "number"
                      ? state.revsharePercentages.default
                      : ""
                  }
                />

                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleSwitch} />}
                  label="Multi-product"
                  labelPlacement="start"
                />
              </div>

              {checked &&
                Object.entries(verticalsMap).map((vertical) => (
                  <TextField
                    className={classes.formControl}
                    id={`${detailsType}-${vertical[1]}-revshare-percentage`}
                    inputProps={{ max: 100, min: 0 }}
                    key={vertical[1]}
                    fullWidth
                    label={`${vertical[0]} Revshare Percentage`}
                    onChange={(event) => {
                      if (event.target.value === "") {
                        const revsharePercentages: {
                          [key in VerticalType]?: number;
                        } = {
                          ...state.revsharePercentages,
                        };
                        delete revsharePercentages[vertical[1]];
                        setInputState({ revsharePercentages });
                      } else {
                        setInputState({
                          revsharePercentages: {
                            ...state.revsharePercentages,
                            [vertical[1] as VerticalType]: parseFloat(
                              event.target.value
                            ),
                          },
                        });
                      }
                    }}
                    type="number"
                    value={
                      state.revsharePercentages &&
                      typeof state.revsharePercentages[vertical[1]] === "number"
                        ? state.revsharePercentages[vertical[1]]
                        : ""
                    }
                  />
                ))}
            </>
          )}

        {state.commissionType && state.commissionType === CommissionType.SPEND && (
          <>
            <TextField
              className={classes.formControl}
              id={`${detailsType}-spend-access-token`}
              fullWidth
              label="Access Token"
              onChange={(event) =>
                setInputState({
                  spendAccessToken: event.target.value || emptyStringValue,
                })
              }
              required
              value={state.spendAccessToken || ""}
            />

            <TextField
              className={classes.formControl}
              id={`${detailsType}-spend-percentage`}
              inputProps={{ max: 100, min: 0, step: 0.01 }}
              fullWidth
              label="Spend Percentage"
              onChange={(event) =>
                setInputState({
                  spendPercentage: parseFloat(event.target.value),
                })
              }
              required
              type="number"
              value={
                state.spendPercentage === 0 || state.spendPercentage
                  ? state.spendPercentage
                  : ""
              }
            />
          </>
        )}

        <FormControl className={classes.formControl} fullWidth required>
          <InputLabel htmlFor={`${detailsType}-currency`}>Currency</InputLabel>
          <Select
            inputProps={{ id: `${detailsType}-currency` }}
            native
            onChange={(event) =>
              setInputState({ currency: event.target.value as Currency })
            }
            required
            value={state.currency}
          >
            <option value="" />
            {Object.keys(Currency).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </Select>
        </FormControl>
        {(state.commissionType === CommissionType.HYBRID ||
          state.commissionType === CommissionType.REVSHARE) &&
          detailsType === "affiliate" && (
            <>
              <FormGroup row>
                <Tooltip
                  arrow
                  placement="right"
                  title="If checked, a negative monthly subtotal on this agreement will be shown as a zero in the monthly payments report. If unchecked, a negative monthly total for given agreement will be included with all other partner revshare earnings"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ringfenceRevshareState}
                        onChange={handleRingfenceChange}
                        name="ringfenceRevshare"
                      />
                    }
                    label="Ringfence Negative Revshare"
                  />
                </Tooltip>
              </FormGroup>
            </>
          )}
      </>
    );
  };

  const renderQualifications = () => {
    return qualificationsState.map((qualification, index) => (
      <div key={index}>
        <FormControl className={classes.formControl} fullWidth required>
          <InputLabel id={`qualification-${index}`}>Qualification Action</InputLabel>
          <Select
            input={<Input />}
            inputProps={{ name: `qualificationType-${index}` }}
            labelId={`qualification-${index}`}
            onChange={handleQualificationChange}
            required
            value={qualification.qualificationType || ""}
          >
            {Object.values(QualificationType).map((qualificationType) => {
              const qualificationIndex = qualificationsState.findIndex(
                (q) => q.qualificationType === qualificationType
              );
              if (qualificationIndex < 0 || qualificationIndex === index) {
                let menuText: string = "";

                switch (qualificationType) {
                  case "MIN_DEPOSIT":
                    menuText = "Min Deposit Amount (Cumulative)";
                    break;
                  case "MIN_WAGER_AMOUNT":
                    menuText = "Min Wager Amount (Cumulative)";
                    break;
                  case "MIN_WAGER_COUNT":
                    menuText = "Min Wager Count (Cumulative)";
                    break;
                  case "DEPOSIT_WINDOW":
                    menuText = "Max Deposit Window";
                    break;
                }
                return (
                  <MenuItem key={qualificationType} value={qualificationType}>
                    {menuText}
                  </MenuItem>
                );
              } else {
                return undefined;
              }
            })}
          </Select>
        </FormControl>

        {qualification.qualificationType === QualificationType.DEPOSIT_WINDOW && <FormControl className={classes.formControl} fullWidth required>
          <InputLabel htmlFor={`windowType-${index}`}>
            Window Event Type
          </InputLabel>
          <Select
            input={<Input />}
            inputProps={{ name: `windowType-${index}` }}
            labelId={`qualification-${index}`}
            onChange={handleQualificationChange}
            required
            value={qualification.windowType || ""}
          >
            {Object.keys(QualificationWindowType).map((windowType) => (
              <MenuItem key={windowType} value={windowType}>
                {windowType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}

        <TextField
          className={classes.formControl}
          fullWidth
          inputProps={{ name: `amount-${index}`, min: 0, step: qualification.qualificationType === QualificationType.DEPOSIT_WINDOW ? 1 : 0.01 }}
          label="Amount"
          onChange={handleQualificationChange}
          onBlur={handleQualificationBlur}
          required
          type="number"
          value={
            qualification.amount === 0 || qualification.amount
              ? qualification.amount
              : ""
          }
        />
      </div>
    ));
  };

  const renderTiers = (payType: PayType, agreementTierType: AgreementTierType) => {
    const agreementState =
      payType === PayType.NETWORK ? networkDetailsState : affiliateDetailsState;
    const tiersState =
      payType === PayType.NETWORK ? networkTiersState : partnerTiersState;

    const subagreementThesholdTypes = {
      FIRST_TIME_DEPOSIT: ThresholdType.FIRST_TIME_DEPOSIT,
      QUALIFIED_DEPOSIT: ThresholdType.QUALIFIED_DEPOSIT,
      NET_REVENUE: ThresholdType.NET_REVENUE,
    }
    const clickThresholdTypes = {
      DEPOSIT_AMOUNT: ThresholdType.DEPOSIT_AMOUNT,
      DEPOSIT_COUNT: ThresholdType.DEPOSIT_COUNT,
      DEPOSIT_WINDOW: ThresholdType.DEPOSIT_WINDOW,
      WAGER_AMOUNT: ThresholdType.WAGER_AMOUNT,
      WAGER_COUNT: ThresholdType.WAGER_COUNT,
      WAGER_WINDOW: ThresholdType.WAGER_WINDOW,
    }

    return tiersState.map((tier, index) => (
      <div key={index}>
        <Typography>{`${payType} Tier ${index + 1}`}</Typography>

        <FormControl
          className={classes.formControl}
          disabled={index >= 1}
          required
        >
          <InputLabel htmlFor={`${payType}-tier-${index + 1}-threshold-type`}>
            Threshold Type
          </InputLabel>
          <Select
            inputProps={{
              id: `${payType}-tier-${index + 1}-threshold-type`,
              name: `thresholdType-${index}`,
            }}
            native
            onChange={(event) => handleTierChange(event, payType)}
            value={tier.thresholdType || ""}
          >
            <option value="" />
            {Object.keys(agreementTierType === AgreementTierType.SUBAGREEMENT ? subagreementThesholdTypes : clickThresholdTypes).map((thresholdType) => (
              <option key={thresholdType} value={thresholdType}>
                {thresholdType.replace(/_/g, " ")}
              </option>
            ))}
          </Select>
        </FormControl>

        {[ThresholdType.DEPOSIT_WINDOW, ThresholdType.WAGER_WINDOW].includes(tier.thresholdType!) && (
          <FormControl className={classes.formControl} disabled={index >= 1} fullWidth required>
            <InputLabel htmlFor={`${payType}-tier-${index + 1}-window-type`}>
              Window Event Type
            </InputLabel>
            <Select
              inputProps={{
                id: `${payType}-tier-${index + 1}-window-type`,
                name: `windowType-${index}`,
              }}
              native
              onChange={(event) => handleTierChange(event, payType)}
              required
              value={tier.windowType || ""}
            >
              <option value="" />
              {Object.keys(TierWindowType).map((windowType) => (
                <option key={windowType} value={windowType}>
                  {windowType.replace(/_/g, " ")}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          className={classes.formControl}
          fullWidth
          inputProps={{ name: `threshold-${index}`, min: 0, step: 0.01 }}
          label="Threshold"
          onChange={(event) => handleTierChange(event, payType)}
          required
          type="number"
          value={tier.threshold === 0 || tier.threshold ? tier.threshold : ""}
        />

        {agreementState?.commissionType !== CommissionType.REVSHARE && (
          <TextField
            className={classes.formControl}
            fullWidth
            inputProps={{ name: `cpa-${index}`, min: 0, step: 0.01 }}
            label="CPA Amount"
            onChange={(event) => handleTierChange(event, payType)}
            required={agreementState.commissionType === CommissionType.CPA}
            type="number"
            value={tier.cpa === 0 || tier.cpa ? tier.cpa : ""}
          />
        )}

        {agreementState?.commissionType !== CommissionType.CPA && (
          <TextField
            className={classes.formControl}
            fullWidth
            inputProps={{ name: `revsharePercentages-${index}` }}
            label="RS %"
            onChange={(event) => handleTierChange(event, payType)}
            required={agreementState.commissionType === CommissionType.REVSHARE}
            type="number"
            value={
              tier.revsharePercentages?.default ||
              tier.revsharePercentages?.default === 0
                ? tier.revsharePercentages.default
                : ""
            }
          />
        )}
      </div>
    ));
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>

            {enableStartEndDates && (
              <>
                <DialogContentText className={classes.dateDialog}>
                  Start Date
                </DialogContentText>
                <TextField
                  type="datetime-local"
                  className={classes.formControl}
                  value={startState || ""}
                  onChange={(event) => {
                    setDirty(true);

                    if (!event.target.value) {
                      setStartState(null);
                      return;
                    }

                    let chosenDate = new Date(event.target.value);
                    if (chosenDate < new Date()) {
                      chosenDate = new Date();
                    }
                    if (endState && chosenDate > new Date(endState)) {
                      setEndState(format(chosenDate, "yyyy-MM-dd'T'HH:mm"));
                    }
                    setStartState(format(chosenDate, "yyyy-MM-dd'T'HH:mm"));
                  }}
                />
                <DialogContentText className={classes.dateDialog}>
                  End Date
                </DialogContentText>
                <TextField
                  type="datetime-local"
                  className={classes.formControl}
                  value={endState || ""}
                  onChange={(event) => {
                    setDirty(true);

                    if (!event.target.value) {
                      setEndState(null);
                      return;
                    }

                    let chosenDate = new Date(event.target.value);
                    const startDate = startState
                      ? new Date(startState)
                      : new Date();
                    if (chosenDate < startDate) {
                      chosenDate = startDate;
                    }
                    setEndState(format(chosenDate, "yyyy-MM-dd'T'HH:mm"));
                  }}
                />
              </>
            )}

            {(createNetworkDetails || updateNetworkDetails) && (
              <>
                <Typography variant="h6">Network Pay-In</Typography>
                {renderAgreementDetails("network")}
              </>
            )}

            <Typography variant="h6">Partner Pay-Out</Typography>
            {renderAgreementDetails("affiliate")}

            <div className={classes.formControl}>
              <div className={classes.formHeader}>
                <Typography variant="h6">Qualifications</Typography>
                <Switch
                  checked={!!qualificationsState?.length}
                  onChange={handleQualificationSwitchChange}
                  name="qualification"
                />
              </div>

              {!!qualificationsState?.length && (
                <>
                  {renderQualifications()}

                  <div className={classes.formButtons}>
                    <Button onClick={handleRemoveQualification}>
                      Remove Qualification
                    </Button>
                    <Button
                      disabled={loading || qualificationsState.length >= 4}
                      onClick={handleAddQualification}
                      variant="contained"
                    >
                      Add Qualification
                    </Button>
                  </div>
                </>
              )}
            </div>

            <>
              {(createNetworkDetails || updateNetworkDetails) && networkDetailsState.commissionType !== CommissionType.CLAWBACK && (
                <div className={classes.formControl}>
                  <div className={classes.formHeader}>
                    <Typography variant="h6">Network Tiers</Typography>
                    <Switch
                      checked={!!networkTiersState.length}
                      onChange={(event) =>
                        handleTierSwitchChange(event, PayType.NETWORK)
                      }
                      name="Network Tiers"
                    />
                  </div>

                  {!!networkTiersState.length && (
                    <>
                      <FormControl className={classes.formControl} fullWidth required>
                        <InputLabel htmlFor="network-tier-type">
                          Network Tier Grouping Type
                        </InputLabel>
                        <Select
                          inputProps={{ id: "network-tier-type" }}
                          native
                          onChange={(event) => handleAgreementTierChange(event, PayType.NETWORK)}
                          required
                          value={networkDetailsState.tierType || ""}
                        >
                          <option value="" />
                          {Object.keys(AgreementTierType).map((tierType) => (
                            <option key={tierType} value={tierType}>
                              {tierType}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      {renderTiers(PayType.NETWORK, networkDetailsState.tierType || AgreementTierType.SUBAGREEMENT)}

                      <div className={classes.formButtons}>
                        <Button
                          onClick={() => handleRemoveTier(PayType.NETWORK)}
                        >
                          Remove Tier
                        </Button>
                        <Button
                          disabled={loading}
                          onClick={() => handleAddTier(PayType.NETWORK)}
                          variant="contained"
                        >
                          Add Tier
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}

              {affiliateDetailsState.commissionType !== CommissionType.CLAWBACK && (
                <div className={classes.formControl}>
                  <div className={classes.formHeader}>
                    <Typography variant="h6">Partner Tiers</Typography>
                    <Switch
                      checked={!!partnerTiersState.length}
                      onChange={(event) =>
                        handleTierSwitchChange(event, PayType.PARTNER)
                      }
                      name="Partner Tiers"
                    />
                  </div>

                  {!!partnerTiersState.length && (
                    <>
                      <FormControl className={classes.formControl} fullWidth required>
                        <InputLabel htmlFor="partner-tier-type">
                          Partner Tier Grouping Type
                        </InputLabel>
                        <Select
                          inputProps={{ id: "partner-tier-type" }}
                          native
                          onChange={(event) => handleAgreementTierChange(event, PayType.PARTNER)}
                          required
                          value={affiliateDetailsState.tierType || ""}
                        >
                          <option value="" />
                          {Object.keys(AgreementTierType).map((tierType) => (
                            <option key={tierType} value={tierType}>
                              {tierType}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      {renderTiers(PayType.PARTNER, affiliateDetailsState.tierType || AgreementTierType.SUBAGREEMENT)}

                      <div className={classes.formButtons}>
                        <Button onClick={() => handleRemoveTier(PayType.PARTNER)}>
                          Remove Tier
                        </Button>
                        <Button
                          disabled={loading}
                          onClick={() => handleAddTier(PayType.PARTNER)}
                          variant="contained"
                        >
                          Add Tier
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={!dirty || loading}
              type="submit"
              variant="contained"
            >
              {type.toUpperCase()}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={onCloseReset}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  dateDialog: { margin: 0 },
  formButtons: { display: "flex", justifyContent: "flex-end" },
  formControl: { paddingBottom: spacing(2) },
  formGroup: { display: "flex" },
  formHeader: { display: "flex", justifyContent: "space-between" },
  formRevshare: { flex: "auto" },
}));

export default AgreementForm;
