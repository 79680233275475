import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Breadcrumbs,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";

import { AccountPool_accountPool_accountPool } from "../../generated/AccountPool";

import AccountList from "../../components/AccountList";

interface IAccountListProps {
  accountPool: AccountPool_accountPool_accountPool;
}

const AccountPoolShow = ({ accountPool }: IAccountListProps) => {
  const history = useHistory();

  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <Paper>
        <div className={classes.header}>
          <Breadcrumbs>
            <Typography
              className={classes.breadcrumb}
              onClick={() => history.push("/account-pools")}
            >
              {`< Account Pools`}
            </Typography>
          </Breadcrumbs>

          <Typography variant="h4">{accountPool.name}</Typography>
        </div>

        <AppBar color="default" elevation={0} position="static">
          <Tabs
            onChange={(_, value: number) => setTabIndex(value)}
            value={tabIndex}
          >
            <Tab label="Details" />
            <Tab label="Accounts" />
          </Tabs>
        </AppBar>

        <div className={classes.tabContent} hidden={tabIndex !== 0}>
          <Typography>{`Description: ${accountPool.description}`}</Typography>
        </div>

        <div className={classes.tabContent} hidden={tabIndex !== 1}>
          <AccountList
            accountPoolId={accountPool.id}
            accountPoolName={accountPool.name}
            accountPoolVariables={accountPool.variables}
          />
        </div>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  breadcrumb: {
    cursor: "pointer"
  },
  header: {
    padding: spacing(2)
  },
  tabContent: {
    padding: spacing(2)
  }
}));

export default AccountPoolShow;
